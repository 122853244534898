import React from 'react';
import './DashboardPurchaseOrders.scss';
import { Query, QueryResult } from 'react-apollo';
import { GET_ALL_PURCHASEORDER_LIST } from '../../graphql/client/queries';
import { useStoreContext } from '../App/StoreProvider';
import { Error, Loading } from '../_common';
import {
    GetPurchaseOrderList_getPurchaseOrderList as PurchaseOrder,
    GetPurchaseOrderList,
} from '../../types/schemaTypes';
import openfolder from '../../assets/openfolder.png';
import Table from 'react-bootstrap/Table';
import { Routes } from '../../utils/Routes';
import moment from 'moment';

const DashboardPurchaseOrders = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        customerList: { customer },
    } = state;

    const openPurchaseOrders = (): void => {
        const URL = `${window.location.origin}${Routes.PURCHASE_ORDERS.route}`;
        window.location.replace(URL);
    };

    const variables = {
        input: {
            searchString: '',
            customer,
            fromDate: null,
            toDate: null,
            pageSize: 10,
            offSet: 0,
            sortParam: 'customerPODate',
            sortDirection: 'desc',
            product: '',
            poNumber: '',
            pickupLocation: '',
            status: '1',
            shipTo: '',
        },
    };
    return (
        <Query
            query={GET_ALL_PURCHASEORDER_LIST}
            variables={variables}
            skip={customer === 0}
            fetchPolicy="network-only"
        >
            {({ error, data, loading }: QueryResult<GetPurchaseOrderList | undefined>): JSX.Element | null => {
                const purchaseOrderList: PurchaseOrder[] = [];
                if (data && data.getPurchaseOrderList && data.getPurchaseOrderList.length !== 0) {
                    data.getPurchaseOrderList.forEach((element: PurchaseOrder | null): void => {
                        if (element !== null) {
                            purchaseOrderList.push(element);
                        }
                    });
                }

                return (
                    <div className="Dashboard-PurchaseOrdersContainer">
                        {customer != 0 && error && <Error error={error} />}
                        <div>
                            <label className="Dashboard-PurchaseOrders-Headings">My Purchase Orders</label>
                            &nbsp;&nbsp;
                            <img alt="" src={openfolder} className="Logo" />
                        </div>
                        <label className="Label-Class">The following purchase orders are open:</label>
                        <div className="PO-Table-Container">
                            {customer != 0 && <div className="Loading-Class">{loading && <Loading />}</div>}
                            <table className="PO-Table">
                                <thead className="PO-Table-Head">
                                    <tr className="PO-Table-Rows">
                                        <th className="PO-Table-Heading">PO Number</th>
                                        <th className="PO-Table-Heading">PO Value</th>
                                        <th className="PO-Table-Heading">Date</th>
                                        <th className="PO-Table-Heading">UoM</th>
                                        <th className="PO-Table-Heading">PO Qty.</th>
                                        <th className="PO-Table-Heading">Qty. shipped</th>
                                    </tr>
                                </thead>
                                <tbody className="PO-Table-Body">
                                    {purchaseOrderList.map((info, index) => (
                                        <tr key={index} className="PO-Table-Rows">
                                            <td className="PO-Table-Values">{info.customerPONumber}</td>
                                            <td className="PO-Table-Values">{`$${info.poValue}`}</td>
                                            <td className="PO-Table-Values">{info.customerPODate}</td>
                                            <td className="PO-Table-Values">{info.uom}</td>
                                            <td className="PO-Table-Values">{info.quantityonPO}</td>
                                            <td className="PO-Table-Values">{info.quantityShipped}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="PO-Button-class">
                            <button className="PO-Dashboard-button" onClick={openPurchaseOrders}>
                                See All Purchase Orders
                            </button>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default DashboardPurchaseOrders;
