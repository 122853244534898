import React from 'react';
import './DashboardOpenInvoices.scss';
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Query, QueryResult } from 'react-apollo';
import { GET_OPEN_INVOICE_AGE } from '../../graphql/client/queries';
import { useStoreContext } from '../App/StoreProvider';
import { GetOpenInvoiceAge } from '../../types/schemaTypes';
import { GetOpenInvoiceAge_getOpenInvoiceAge as OpenInvoice } from '../../types/schemaTypes';
import { Loading } from '../_common';

const DashboardOpenInvoices = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        customerList: { customer },
    } = state;

    const variables = {
        input: {
            searchString: '',
            customer,
            addressNumber: 0,
            invoiceStatus: 1,
            pageSize: 0,
            offSet: 0,
            sortParam: 'invoiceDate',
            sortDirection: 'desc',
            invoiceNumber: '',
            poNumber: '',
        },
    };

    const formatXAxis = tickValue => {
        if (tickValue == 4500000) {
            return `>$${tickValue}`;
        } else {
            return `$${tickValue}`;
        }
    };
    return (
        <Query query={GET_OPEN_INVOICE_AGE} variables={variables} skip={customer === 0} fetchPolicy="network-only">
            {({
                error,
                data,
                loading,
                refetch,
                fetchMore,
            }: QueryResult<GetOpenInvoiceAge | undefined>): JSX.Element | null => {
                const invoiceList: any[] = [];
                //console.log('sundeep' + JSON.stringify(data));
                if (data && data.getOpenInvoiceAge) {
                    invoiceList.push({
                        OpenAmount: data.getOpenInvoiceAge.overSixty,
                        Range: 'Over 60',
                    });
                    invoiceList.push({
                        OpenAmount: data.getOpenInvoiceAge.thirtyOneSixty,
                        Range: '31 to 60',
                    });
                    invoiceList.push({
                        OpenAmount: data.getOpenInvoiceAge.sixteenThirty,
                        Range: '16 to 30',
                    });
                    invoiceList.push({
                        OpenAmount: data.getOpenInvoiceAge.oneFifteen,
                        Range: '1 to 15',
                    });
                    invoiceList.push({
                        OpenAmount: data.getOpenInvoiceAge.current,
                        Range: 'Current',
                    });
                }

                return (
                    <div className="Dashboard-OpenInvoices-Container">
                        <div className="Dashboard-OpenInvoices-SearchBar">
                            <label className="OpenInvoices-Headings" htmlFor="staticEmail">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Open Invoices — Aging Summary
                            </label>
                            <div>
                                {customer != 0 && <div className="Loading-Class">{loading && <Loading />}</div>}
                                <ResponsiveContainer width={520} height={180} className="Chart-Class">
                                    <BarChart
                                        data={invoiceList}
                                        layout="vertical"
                                        margin={{
                                            top: 20,
                                            bottom: 15,
                                        }}
                                    >
                                        <Bar dataKey="OpenAmount" fill="gray" />
                                        <CartesianGrid horizontal={false} />
                                        <XAxis
                                            type="number"
                                            dataKey="OpenAmount"
                                            style={{ textAnchor: 'middle', fontSize: '10px' }}
                                            interval={0}
                                            domain={[0, 8600000]}
                                            angle={40}
                                            dx={15}
                                            dy={15}
                                            ticks={[0, 250000, 500000, 1000000, 2000000, 4000000, 8000000]}
                                            tickFormatter={tickValue =>
                                                formatXAxis(tickValue).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                                            }
                                            tickCount={8}
                                        />
                                        <YAxis
                                            type="category"
                                            dataKey="Range"
                                            interval={0}
                                            width={50}
                                            style={{ textAnchor: 'end', fontSize: '10px', fill: 'black' }}
                                        />
                                        <Tooltip />
                                    </BarChart>
                                </ResponsiveContainer>
                                <label className="xaxislabel">Open Amount</label>
                                <label className="yaxislabel">Days Beyond Payment Terms</label>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default DashboardOpenInvoices;
