import React, { FC, createElement } from 'react';
import { useAuthContext } from '../AuthProvider';
import { Route, Redirect } from 'react-router-dom';
import { Loading } from '../../_common';
import { useStoreContext } from '../../App/StoreProvider';

interface PrivateRouteProps {
    component: FC;
    path: string;
    exact: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component, ...rest }: PrivateRouteProps): JSX.Element => {
    const { userManager } = useAuthContext();
    const [{ auth }] = useStoreContext();

    const routeComponent = (props: any): JSX.Element => {
        if (auth.user && !auth.user.expired) {
            return createElement(component, props);
        } else {
            userManager.removeUser();
            userManager.clearStaleState();

            return (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            );
        }
    };

    return !auth.isUserLoading ? <Route {...rest} render={routeComponent} /> : <Loading />;
};

export default PrivateRoute;
