import React, { useState } from 'react';
import { GetB2CUserFiltered_getB2CUserFiltered as B2CUser } from '../../../types/schemaTypes';
import './UserItem.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { CPOption, Option } from '../interface';

interface UserItemProps {
    user: B2CUser;
    options: Option[];
    cpOptions: CPOption[];
    index: number;
    canEditUsers: boolean;
    onEditClick: (user: B2CUser) => void;
    onDelete?: (record: any) => void;
}

const UsersItem = ({
    user,
    options,
    cpOptions,
    index,
    onEditClick,
    onDelete,
    canEditUsers,
}: UserItemProps): JSX.Element => {
    /* eslint-disable prefer-const */
    let { firstName, lastName, emailAddress, roleId, roleName, isActive, customerParentId } = user;
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const handleDelete = () => {
        onDelete && onDelete(user);
        setDeleteConfirm(false);
    };

    const getCustomerParentName = (): string | number | null => {
        const id = customerParentId ? customerParentId.toString() : '';
        if (id == '') return null;
        const numId = parseInt(id);
        const filteredCustomerParent = cpOptions.filter((item: CPOption) => item.id === numId);
        // const role = filteredCustomerParent[0].name;
        const role = filteredCustomerParent[0] ? filteredCustomerParent[0].name : '';
        return role;
    };

    const getCustomerParentJdeId = (): string | number | null => {
        const id = customerParentId ? customerParentId.toString() : '';
        if (id == '') return null;
        const numId = parseInt(id);
        const filteredCustomerParent = cpOptions.filter((item: CPOption) => item.id === numId);
        // const jdeId = filteredCustomerParent[0].jdeId;
        const jdeId = filteredCustomerParent[0] ? filteredCustomerParent[0].jdeId : '';
        return jdeId;
    };

    const isOdd = index % 2 == 1;
    const containerStyle = `UsersItem-Bottom-Container ${!isOdd && 'UsersItem-Bottom-Container-With-Background'}`;

    //const option = cpOptions.find(element => element.id == roleId);

    //const role: Option = option ? option : { id: 0, name: '<None>' };

    if (roleName === '') {
        const filteredRole = options.filter((role: Option) => role.id === roleId);
        roleName = filteredRole[0].name ? filteredRole[0].name.toString() : '';
        user.roleName = roleName;
    }

    return (
        <div className={containerStyle}>
            <div className="UsersItem-List-Header">
                {firstName} {lastName}
            </div>
            <div className="UsersItem-List-Header Users-Header-Title-Mid">{emailAddress}</div>
            <div className="UsersItem-List-Header">{roleName}</div>
            <div className="UsersItem-List-Header Users-Header-Title-Lg">{getCustomerParentName()}</div>
            <div className="UsersItem-List-Header UsersItem-List-Header-Mid">{getCustomerParentJdeId()}</div>
            <div className="UsersItem-List-Header">{isActive ? 'Active' : 'Inactive'}</div>

            {!canEditUsers && <div className="UsersItem-List-Header"></div>}
            {canEditUsers && (
                <div className="UsersItem-List-Header">
                    {canEditUsers && (
                        <div
                            className={` ${
                                canEditUsers ? 'UsersItem-Edit-Container' : 'UsersItem-Edit-Container-Disable'
                            }`}
                            onClick={() => onEditClick(user)}
                        >
                            <i className="material-icons UsersItem-Icon">edit</i>
                            <span className="UsersItem-Link">Edit</span>
                        </div>
                    )}
                    {/* &nbsp;|&nbsp;
                    {canEditUsers && onDelete && (
                        <div onClick={() => setDeleteConfirm(true)} className={` ${'GridViewItem-Edit-Container'}`}>
                            <i className="material-icons UsersItem-Icon">delete</i>
                            <span className="UsersItem-Link">Delete</span>
                        </div>
                    )} */}
                </div>
            )}
        </div>
    );
};

export default UsersItem;
