import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import client from '../../graphql/client';
import { useStore } from '../../utils';
import Frame from './Frame';
import { AuthProvider } from '../Auth/AuthProvider';
import { StoreProvider } from './StoreProvider';

const App = (): JSX.Element => {
    const store = useStore();

    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <StoreProvider store={store}>
                    <AuthProvider>
                        <Frame />
                    </AuthProvider>
                </StoreProvider>
            </BrowserRouter>
        </ApolloProvider>
    );
};

export default App;
