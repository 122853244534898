import gql from 'graphql-tag';

export default gql`
    query GetPurchaseOrderFilterShipTo($input: GetAllPurchaseOrderFilterShipToInput) {
        getPurchaseOrderFilterShipTo(input: $input) {
            shipToId
            shipToName
        }
    }
`;
