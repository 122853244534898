import { Action } from '../../types';

export enum Actions {
    UPDATE_SEARCH_PHRASE = 'CUSTOMER_LIST_UPDATE_SEARCH_PHRASE',
    UPDATE_CUSTOMER_LIST = 'UPDATE_CUSTOMER_LIST',
    UPDATE_CUSTOMER_ID = 'UPDATE_CUSTOMER_ID',
}

export const setSearchPhrase = (searchPhrase: string): Action => {
    console.log('searchPhrase', searchPhrase);
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};

export const setCustomerList = (customerList): Action => {
    return {
        type: Actions.UPDATE_CUSTOMER_LIST,
        payload: customerList,
        error: null,
    };
};

export const setCustomerID = (customerId): Action => {
    return {
        type: Actions.UPDATE_CUSTOMER_ID,
        payload: customerId,
        error: null,
    };
};
