import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './BillsLadingHeader.scss';

import { useStoreContext } from '../../App/StoreProvider';
import CustomerDropDown from '../CustomerDropDown';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

const BillsLadingHeader = ({ onDownloadBols, onPrintBols, onDownloadInvoices }: any): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        bol: { searchPhrase, fromDate, toDate, bolNumber, pickupLocation, region, wellName, scaleTicket },
        customerList: { customer },
    } = state;

    const onDownloadExcel = (e): void => {
        console.log('onDownloadExcel');
        console.log(
            'BillsLadingHeader - customer',
            customer,
            'process.env.REACT_APP_API_URL,',
            process.env.REACT_APP_API_URL,
            'process.env.REACT_APP_AUTHORITY_URL',
            process.env.REACT_APP_AUTHORITY_URL,
        );
        const queryparams = `?Customer=${customer}&SearchString=${searchPhrase}&FromDate=${
            fromDate ? fromDate : ''
        }&Todate=${toDate ? toDate : ''}&PageSize=0&bolNumber=${bolNumber}&pickupLocation=${pickupLocation}
        &region=${region}&wellName=${wellName}&scaleTicket=${scaleTicket}`;
        window.open(`${process.env.REACT_APP_API_URL}BOL/ExportBol${queryparams}`, '_blank');
    };

    return (
        <div className="BL-Header-Container">
            <div className="BL-Header-SearchBar">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-3 my-auto">
                                <label htmlFor="staticEmail">Customer</label>
                            </div>
                            <div className="col-md-6">
                                <CustomerDropDown></CustomerDropDown>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 text-right">
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadExcel}>
                            Download to Excel
                        </button>
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadBols}>
                            Download BOLS
                        </button>
                        <button className="Button CustomButton-Plain mx-1" onClick={onPrintBols}>
                            Print BOLS
                        </button>
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadInvoices}>
                            Download Invoices
                        </button>
                    </div>
                </div>
            </div>
            <div className="Header-ListHeader"></div>
        </div>
    );
};

export default BillsLadingHeader;
