import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import Callback from '../Callback';
import Login from '../Login';
import { Routes } from '../../../utils';

const AuthContent: FC = (): JSX.Element => (
    <Switch>
        <Route strict path={Routes.CALLBACK.route} component={Callback} />
        <Route component={Login} />
    </Switch>
);

export default AuthContent;
