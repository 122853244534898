import './Filter.scss';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Error, Loading } from '../_common';
import { Query, QueryResult } from 'react-apollo';
import { useStoreContext } from '../App/StoreProvider';
import * as BOLActions from '../../store/actions/bol';
import {
    GetBolPickUpLocationList_getBolPickUpLocationList as BolPickUpLocation,
    GetBolPickUpLocationList,
} from '../../types/schemaTypes';
import { GET_ALL_BOL_PICKUPLOCATION_LIST } from '../../graphql/client/queries';

interface JobFiltersProps {
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const BillsLadingFilters = ({ abortRequest, onFilterToggle }: JobFiltersProps): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        bol: {
            searchPhrase,
            fromDate,
            toDate,
            bolNumber,
            region,
            wellName,
            scaleTicket,
            poNo,
            invoiceNo,
            pickupLocation,
        },
        customerList: { customer },
    } = state;
    const search: React.Ref<HTMLInputElement> = React.createRef();
    const searchBOL: React.Ref<HTMLInputElement> = React.createRef();
    const searchPO: React.Ref<HTMLInputElement> = React.createRef();
    const searchWellName: React.Ref<HTMLInputElement> = React.createRef();
    const searchAltBOL: React.Ref<HTMLInputElement> = React.createRef();
    const searchInvoice: React.Ref<HTMLInputElement> = React.createRef();

    const [filter_searchPhrase, setSearchPhrase] = useState(searchPhrase);
    const [filter_fromDate, setFromDate] = useState(fromDate);
    const [filter_toDate, setToDate] = useState(toDate);
    const [filter_bolNumber, setBOLNumber] = useState(bolNumber);
    const [filter_region, setRegion] = useState(region);
    const [filter_wellName, setWellName] = useState(wellName);
    const [filter_scaleTicket, setScaleTicket] = useState(scaleTicket);
    const [filter_poNo, setPoNo] = useState(poNo);
    const [filter_invoiceNo, setInvoiceNo] = useState(invoiceNo);
    const [filter_pickupLocation, setPickupLocation] = useState(pickupLocation);
    const [isApplyFilterClicked, setIsApplyFilterClicked] = useState(false);

    // useEffect(() => {
    //     setSearchPhrase('');
    //     setFromDate(null);
    //     setToDate(null);
    //     setBOLNumber('');
    //     setRegion('0');
    //     setWellName('');
    //     setScaleTicket('');
    //     setPoNo('');
    //     setInvoiceNo('');
    //     setPickupLocation(0);
    // //     resetFilters();
    // }, []);

    useEffect(() => {
        setSearchPhrase('');
        // setFromDate(null);
        // setToDate(null);
        setBOLNumber('');
        setRegion('0');
        setWellName('');
        setScaleTicket('');
        setPoNo('');
        setInvoiceNo('');
        setPickupLocation(0);
    }, [customer]);

    useEffect(() => {
        setPoNo(poNo);
    }, [poNo]);

    useEffect(() => {
        setFromDate(fromDate);
    }, [fromDate]);

    useEffect(() => {
        setToDate(toDate);
    }, [toDate]);

    useEffect(() => {
        setBOLNumber(bolNumber);
    }, [bolNumber]);

    const updateSearchPhrase = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const searchPhrase = search && search.current ? search.current.value : '';
            // dispatch(BOLActions.setFilterValue(searchPhrase, BOLActions.Actions.FILTER_SEARCHSTRING));
        }
    };

    const onSearchPhraseChange = (e): void => {
        console.log('onSearchPhraseChange', e.target.value);
        setSearchPhrase(e.target.value);
    };

    const updateSearchBOL = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const search = searchBOL && searchBOL.current ? searchBOL.current.value : '';
            // dispatch(BOLActions.setFilterValue(search, BOLActions.Actions.FILTER_BOLNUMBER));
        }
    };

    const onBOLChange = (e): void => {
        console.log('onBOLChange', e.target.value);
        setBOLNumber(e.target.value);
    };

    const updateSearchInvoice = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const search = searchInvoice && searchInvoice.current ? searchInvoice.current.value : '';
            // dispatch(BOLActions.setFilterValue(search, BOLActions.Actions.FILTER_INVOICE));
        }
    };

    const onInvoiceChange = (e): void => {
        console.log('onInvoiceChange', e.target.value);
        setInvoiceNo(e.target.value);
    };

    const updateSearchPO = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const search = searchPO && searchPO.current ? searchPO.current.value : '';
            // dispatch(BOLActions.setFilterValue(search, BOLActions.Actions.FILTER_PONO));
        }
    };

    const onPOChange = (e): void => {
        console.log('onPOChange', e.target.value);
        setPoNo(e.target.value);
    };

    const updateSearchWellName = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const search = searchWellName && searchWellName.current ? searchWellName.current.value : '';
            // dispatch(BOLActions.setFilterValue(search, BOLActions.Actions.FILTER_WELLNAME));
        }
    };

    const onWellNameChange = (e): void => {
        console.log('onWellNameChange', e.target.value);
        setWellName(e.target.value);
    };

    const updateSearchAltBol = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const search = searchAltBOL && searchAltBOL.current ? searchAltBOL.current.value : '';
            // dispatch(BOLActions.setFilterValue(search, BOLActions.Actions.FILTER_ALTBOL));
        }
    };

    const onScaleTicketChange = (e): void => {
        console.log('onScaleTicketChange', e.target.value);
        setScaleTicket(e.target.value);
    };

    const onChange = (e): void => {
        console.log('onChange', e.target.value);
    };

    const onFromDateChange = (e): void => {
        console.log('onFromDateChange', e.target.value);
        // if (e.target.value !== '') {
        setFromDate(e.target.value);
        // dispatch(BOLActions.setFilterValue(e.target.value, BOLActions.Actions.FILTER_FROMDATE_SELECT));
        // }
    };

    const onToDateChange = (e): void => {
        console.log('onToDateChange', e.target.value);
        // if (e.target.value !== '') {
        setToDate(e.target.value);
        // dispatch(BOLActions.setFilterValue(e.target.value, BOLActions.Actions.FILTER_TODATE_SELECT));
        // }
    };

    const [activeKey, setActiveKey] = useState('0');

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        document.body.classList.toggle('hide-sidebar', isOpen);
    }, [isOpen]);

    const onRegionChanges = (e): void => {
        console.log('onRegionChanges', e.target.value);
        setRegion(e.target.value);
        // dispatch(BOLActions.setFilterValue(e.target.value, BOLActions.Actions.FILTER_REGION));
    };

    const onPickupLocationChanges = (e): void => {
        console.log('onPickupLocationChanges', e.target.value);
        setPickupLocation(e.target.value);
        // dispatch(BOLActions.setFilterValue(e.target.value, BOLActions.Actions.FILTER_PICKUPLOCATION));
    };

    const applyFilters = () => {
        setIsApplyFilterClicked(true);
        if (filter_fromDate && filter_toDate) {
            dispatch(
                BOLActions.setFilterValue(
                    {
                        searchPhrase: filter_searchPhrase,
                        fromDate: filter_fromDate,
                        toDate: filter_toDate,
                        bolNumber: filter_bolNumber,
                        invoiceNo: filter_invoiceNo,
                        region: filter_region,
                        pickupLocation: Number(filter_pickupLocation),
                        wellName: filter_wellName,
                        scaleTicket: filter_scaleTicket,
                        poNo: filter_poNo,
                    },
                    BOLActions.Actions.BOL_FILTER_ACTION_APPLYFILTER,
                ),
            );
        }
    };

    const resetFilters = () => {
        setIsApplyFilterClicked(false);
        setSearchPhrase('');
        setFromDate(null);
        setToDate(null);
        setBOLNumber('');
        setRegion('0');
        setWellName('');
        setScaleTicket('');
        setPoNo('');
        setInvoiceNo('');
        setPickupLocation(0);
        //dispatch(BOLActions.setFilterValue('', BOLActions.Actions.BOL_FILTER_ACTION_RESETFILTER));
    };

    const variables = {
        input: {
            searchString: searchPhrase,
            customer,
            fromDate,
            toDate,
            bolNumber,
            pickupLocation: 0,
            region,
            wellName,
        },
    };

    return (
        <div id="jobsFilterContainer" className="JobManagement-Filter col-md-3 pr-0">
            <div className="Filter-Container">
                {/*
                <FilterItem
                    abortRequest={abortRequest}
                    title="Purchase Orders Inquiry"
                    items={statusFilters}
                    activeKey={activeKey}
                    isCollection={true}
                    isSearchable={true}
                    onFilterToggle={onFilterToggle}
                />
                */}

                <div>
                    <div className="accordion">
                        <div className="FilterItem-Card card">
                            <div className="CustomToggle-Container">
                                <div className="CustomToggle-Header">
                                    <p className="CustomToggle-Title">Bills of Lading Inquiry</p>
                                    <Button
                                        className="Filter-SelectAll"
                                        variant="link"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <i className="material-icons">swap_horiz</i>
                                    </Button>
                                </div>
                            </div>
                            <div className="CustomToggle-Divider"></div>
                            <div className="collapse show">
                                <div className="FilterItem-Body FilterItem-BodyPadding card-body">
                                    <div className="SearchBar-Container">
                                        <i className="material-icons SearchBar-Icon">search</i>
                                        <input
                                            id="textSearch"
                                            type="text"
                                            placeholder="Search"
                                            className="SearchBar-Input"
                                            autoComplete="off"
                                            ref={search}
                                            value={filter_searchPhrase}
                                            onChange={e => onSearchPhraseChange(e)}
                                            onKeyDown={(e): void => updateSearchPhrase(e)}
                                        />
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>PO Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchPO}
                                                    value={filter_poNo}
                                                    onChange={e => onPOChange(e)}
                                                    onKeyDown={(e): void => updateSearchPO(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>BOL Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchBOL}
                                                    value={filter_bolNumber}
                                                    onChange={e => onBOLChange(e)}
                                                    onKeyDown={(e): void => updateSearchBOL(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Invoice Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchInvoice}
                                                    value={filter_invoiceNo}
                                                    onChange={e => onInvoiceChange(e)}
                                                    onKeyDown={(e): void => updateSearchInvoice(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Date From <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${
                                                        isApplyFilterClicked && !filter_fromDate
                                                            ? 'Filter-Error-Required'
                                                            : ''
                                                    }`}
                                                    value={filter_fromDate ? filter_fromDate : ''}
                                                    onChange={e => onFromDateChange && onFromDateChange(e)}
                                                />
                                                {isApplyFilterClicked && !filter_fromDate && (
                                                    <label style={{ color: 'red' }}>Date from is required</label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Date To <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${
                                                        isApplyFilterClicked && !filter_toDate
                                                            ? 'Filter-Error-Required'
                                                            : ''
                                                    }`}
                                                    value={filter_toDate ? filter_toDate : ''}
                                                    onChange={e => onToDateChange && onToDateChange(e)}
                                                />
                                                {isApplyFilterClicked && !filter_toDate && (
                                                    <label style={{ color: 'red' }}>Date to is required</label>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Region</label>
                                                <select
                                                    className="form-control"
                                                    value={filter_region}
                                                    onChange={e => onRegionChanges(e)}
                                                >
                                                    <option value="0">All</option>
                                                    <option value="R01">NORTH EAST</option>
                                                    <option value="R02">SOUTH</option>
                                                    <option value="R03">MID-CONTINENT</option>
                                                    <option value="R04">PERMIAN</option>
                                                    <option value="R05">CANADA</option>
                                                    <option value="R06">ROCKIES/WEST</option>
                                                    <option value="R07">BAKKEN</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Pick Up Location</label>
                                                {customer !== 0 ? (
                                                    <Query
                                                        query={GET_ALL_BOL_PICKUPLOCATION_LIST}
                                                        variables={variables}
                                                        fetchPolicy="network-only"
                                                    >
                                                        {({
                                                            error,
                                                            data,
                                                            loading,
                                                            refetch,
                                                            fetchMore,
                                                        }: QueryResult<
                                                            GetBolPickUpLocationList | undefined
                                                        >): JSX.Element | null => {
                                                            const bolPickUpLocationList: BolPickUpLocation[] = [];
                                                            // if (error) {
                                                            //     return <Error error={error} />;
                                                            // } else {

                                                            if (
                                                                data &&
                                                                data.getBolPickUpLocationList &&
                                                                data.getBolPickUpLocationList.length !== 0
                                                            ) {
                                                                data.getBolPickUpLocationList.forEach(
                                                                    (element: BolPickUpLocation | null): void => {
                                                                        if (element !== null) {
                                                                            bolPickUpLocationList.push(element);
                                                                        }
                                                                    },
                                                                );
                                                                // console.log('bolPickUpLocationList', bolPickUpLocationList);
                                                            }
                                                            // }
                                                            return (
                                                                <select
                                                                    className="form-control"
                                                                    onChange={e => onPickupLocationChanges(e)}
                                                                    value={filter_pickupLocation}
                                                                >
                                                                    <option value={''}>All</option>
                                                                    {bolPickUpLocationList.map((prd, index) => (
                                                                        <option
                                                                            key={prd.pickUpLocationNumber?.toString()}
                                                                            value={prd.pickUpLocationNumber?.toString()}
                                                                        >
                                                                            {prd.pickUpLocation}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            );
                                                        }}
                                                    </Query>
                                                ) : (
                                                    <select className="form-control">
                                                        <option>All</option>
                                                    </select>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Well Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchWellName}
                                                    value={filter_wellName}
                                                    onChange={e => onWellNameChange(e)}
                                                    onKeyDown={(e): void => updateSearchWellName(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Scale Ticket</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchAltBOL}
                                                    value={filter_scaleTicket}
                                                    onChange={e => onScaleTicketChange(e)}
                                                    onKeyDown={(e): void => updateSearchAltBol(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button className="btn_clear" onClick={resetFilters}>
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button className="btn_apply" onClick={applyFilters}>
                                                    Apply Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
