import React, { FC } from 'react';
import { useAuthContext } from '../AuthProvider';
import { Loading } from '../../_common';
import { Routes } from '../../../utils';
import './Callback.scss';

const Callback: FC = (): JSX.Element => {
    const { userManager } = useAuthContext();

    userManager.signinRedirectCallback().then(user => {
        if (user) window.location.href = `${window.location.origin}${Routes.HOME.route}`;
        else window.location.href = window.location.origin;
    });

    return (
        <span className="Callback-Loading">
            <Loading />
        </span>
    );
};

export default Callback;
