import gql from 'graphql-tag';

export default gql`
    mutation ContactInfo($input: ContactInput) {
        contactInfo(input: $input) {
            emailSent
            errors
        }
    }
`;
