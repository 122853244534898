import React, { FC } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import { GetB2CUserFiltered_getB2CUserFiltered as B2CUser } from '../../types/schemaTypes';
import { useLazyLoading } from '../_common';
import { CPOption, Option } from './interface';
import UserItem from './UserItem';
import './Users.scss';

interface UserListProps {
    users: B2CUser[];
    cpOptions: CPOption[];
    options: Option[];
    isLoading: boolean;
    canEditUsers: boolean;
    isEndOfResults: boolean;
    refetched: boolean;
    setRefetched: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    onLoadMore: () => void;
    onEditClick: (user: B2CUser) => void;
    onDelete: (user: B2CUser) => void;
}

const UserList: FC<UserListProps> = ({
    users,
    cpOptions,
    options,
    isLoading,
    isEndOfResults,
    refetched,
    setRefetched,
    refetch,
    onLoadMore,
    onEditClick,
    onDelete,
    canEditUsers,
}: UserListProps): JSX.Element => {
    const { handleScroll } = useLazyLoading('userList', isLoading, isEndOfResults, onLoadMore);

    if (refetched && refetch) {
        refetch();
        setRefetched(false);
    }

    return (
        <div className="Users-List" onScroll={handleScroll}>
            {users.map((user, index) => (
                <UserItem
                    key={user.userId ? user.userId.toString() : '0'}
                    index={index}
                    canEditUsers={canEditUsers}
                    user={user}
                    options={options}
                    cpOptions={cpOptions}
                    onEditClick={onEditClick}
                    onDelete={onDelete}
                ></UserItem>
            ))}
        </div>
    );
};

export default UserList;
