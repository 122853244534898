import gql from 'graphql-tag';

export default gql`
    query GetBolPickUpLocationList($input: GetAllBOLPickUpLocationInput) {
        getBolPickUpLocationList(input: $input) {
            pickUpLocationNumber
            pickUpLocation
        }
    }
`;
