import gql from 'graphql-tag';

export default gql`
    query GetAllPurchaseOrderItems($input: GetAllPurchaseOrderItemInput) {
        getAllPurchaseOrderItems(input: $input) {
            lineNumber
            delNo
            productNumber
            product
            standardName
            scheduledPickup
            requestedShipDate
            expectedShipDate
            quantity
            uom
            equipmentNo
            sealNo
            actualShipDate
            status
            soldTo
            soldToName
            shipToName
            salesPerson
            originationSite
            notes
        }
    }
`;
