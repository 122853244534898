import gql from 'graphql-tag';

export default gql`
    query GetOpenInvoiceAge($input: GetAllOpenInvoiceInput) {
        getOpenInvoiceAge(input: $input) {
            current
            oneFifteen
            sixteenThirty
            thirtyOneSixty
            overSixty
        }
    }
`;
