import gql from 'graphql-tag';

export default gql`
    query GetPurchaseOrderProductList($input: GetAllPurchaseOrderProductInput) {
        getPurchaseOrderProductList(input: $input) {
            productNumber
            productName
        }
    }
`;
