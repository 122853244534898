import React, { FC, useEffect, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import './Maintenance.scss';
import MaintenanceImg from '../../assets/maintenance.png';
import { Routes } from '../../utils';
import { useStoreContext } from '../App/StoreProvider';

const Maintenance: FC = (): JSX.Element => {
    document.title = 'Maintenance';

    const [isMaintenance] = useState(true);
    useEffect(() => {
        document.body.classList.toggle('under-maintenance', isMaintenance);
    }, [isMaintenance]);

    return (
        <div className="Maintenance-Container">
            <div className="Maintenance-Body row">
                <div id="MaintenanceContainer" className="col-md-12 my-auto text-center">
                    <img src={MaintenanceImg} className="img-maintenance" />
                    <h2>Site is Under Maintenance</h2>
                    <h3>
                        <strong>We are currently undergoing scheduled maintenance, but will return shortly.</strong>
                    </h3>
                    <p>Thank you for your patience.</p>
                </div>
            </div>
        </div>
    );
};

export default Maintenance;
