import React, { useState, useEffect } from 'react';
import { InputTitleEnum } from '../InputTitleEnum/InputTitleEnum';
import InputMask from 'react-input-mask';
import { isFinite, toInteger, toString } from 'lodash';
import { EditActions } from '../../../components/_common/EditLoadErrors/EditLoadErrors';
import { InputGroup, Form } from 'react-bootstrap';
import './Textfield.scss';
import NumberFormat from 'react-number-format';

interface CommonPropsType {
    disabled?: boolean;
    onBlur?: () => void;
    onChange?: (event: any) => void;
}

interface TextfieldProps {
    label: string;
    helperText?: string;
    select?: boolean;
    required?: boolean;
    type?: string;
    min?: string;
    max?: string;
    step?: string;
    maxLength?: number;
    multiline?: boolean;
    rows?: number;
    error?: boolean;
    id?: string;
    mask?: string | (string | RegExp)[];
    name?: string;
    onChange?:
        | ((event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void)
        | undefined;
    value?: string | number | null;
    inputTitle?: string | null;
    onBlur?: (value: string | number | null | undefined) => void;
    disabled?: boolean;
    inputProps?: () => void;
    endIcon?: JSX.Element;
    thousandSeparator?: boolean;
}

export const Textfield = ({
    label,
    name,
    onChange,
    required,
    mask,
    error,
    value,
    onBlur,
    inputTitle,
    disabled,
    helperText,
    endIcon,
    thousandSeparator,
    maxLength,
}: TextfieldProps): JSX.Element => {
    const [inputValue, setInputValue] = useState(toString(value));
    let validation = false;
    let errorMessage = '';
    if (inputTitle === InputTitleEnum.STAGE_TIME) {
        if (value !== null) {
            validation = value && toInteger(value) > 0 && toInteger(value) < 999 ? false : true;
            errorMessage = EditActions.STAGE_TIME_ERROR;
        }
    }
    if (inputTitle === InputTitleEnum.MILES_FROM_WELL) {
        if ((value && isFinite(value)) || value === null || value === '') {
            validation = false;
            errorMessage = '';
        } else {
            validation = value && toInteger(value) > 0 ? false : true;
            errorMessage = EditActions.MILES_FROM_WELL_ERROR;
        }
    }

    const commonProps: CommonPropsType = {
        onBlur: (): void => {
            if (onBlur) {
                onBlur(inputValue);
            }
        },
        onChange: (event: any): void => {
            const cleanValue = mask ? event.target.value.replace(/_+/, '') : event.target.value;
            setInputValue(cleanValue);
            if (!validation && onChange) {
                onChange(event);
            }
        },
    };

    if (!validation) {
        commonProps.disabled = disabled;
    }

    useEffect(() => {
        setInputValue(toString(value));
    }, [value]);
    const textfieldErrors =
        error || validation ? (
            <div className="Textfield-Invalid">
                <Form.Control.Feedback type="invalid" aria-invalid="false">
                    {helperText || errorMessage || 'This field is required'}
                </Form.Control.Feedback>
            </div>
        ) : (
            <div className="Textfield-valid">
                <Form.Control.Feedback type="valid" aria-invalid="true">
                    {error}
                    {helperText}
                </Form.Control.Feedback>
            </div>
        );

    const componentProps = !mask ? commonProps : {};
    const returnComponent = (
        <div className="Textfield-FormContainer">
            <form onSubmit={e => e.preventDefault()}>
                <InputGroup className="Textfield-Validation">
                    <Form.Label>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={error ? 'Textfield-InputTitle Textfield-Error' : 'Textfield-InputTitle'}
                            >
                                {required ? `*${label}` : label}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                    </Form.Label>
                    <div className="Textfield-InputRow">
                        <Form.Control
                            className={error ? 'Textfield-InputBox InputBox-Error' : 'Textfield-InputBox'}
                            type="text"
                            name={name}
                            defaultValue={inputValue ? inputValue : ''}
                            disabled={disabled}
                            maxLength={maxLength}
                            {...componentProps}
                        />
                        {endIcon && (
                            <InputGroup.Append>
                                <InputGroup.Text className={'Textfield-Icon'}>{endIcon}</InputGroup.Text>
                            </InputGroup.Append>
                        )}
                    </div>
                    {textfieldErrors}
                </InputGroup>
            </form>
        </div>
    );

    const returnThousandSeparatorComponent = (
        <div className="Textfield-FormContainer">
            <InputGroup className="Textfield-Validation">
                <Form.Label>
                    <InputGroup.Prepend>
                        <InputGroup.Text
                            className={error ? 'Textfield-InputTitle Textfield-Error' : 'Textfield-InputTitle'}
                        >
                            {required ? `*${label}` : label}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                </Form.Label>
                <div className="Textfield-InputRow">
                    <NumberFormat
                        className={
                            disabled
                                ? 'Textfield-InputBox2 Textfield-Disabled'
                                : error
                                ? 'Textfield-InputBox2 InputBox-Error2'
                                : 'Textfield-InputBox2'
                        }
                        onChange={(event: any): void => {
                            const cleanValue = event.target.value.replace(/,/g, '');
                            setInputValue(cleanValue);
                            if (onChange) {
                                onChange(event.target.value.replace(/,/g, ''));
                            }
                        }}
                        defaultValue={inputValue || ''}
                        thousandSeparator
                        maxLength={maxLength}
                        disabled={disabled}
                    />
                </div>
                {textfieldErrors}
            </InputGroup>
        </div>
    );

    const inputMaskComponents = mask ? commonProps : {};
    if (thousandSeparator) {
        return returnThousandSeparatorComponent;
    } else {
        return mask ? (
            <InputMask {...inputMaskComponents} mask={mask} alwaysShowMask={false} maskPlaceholder="">
                {returnComponent}
            </InputMask>
        ) : (
            returnComponent
        );
    }
};

export default Textfield;
