import React from 'react';
import './DashboardBOLs.scss';
import { Query, QueryResult } from 'react-apollo';
import { GET_ALL_BOL_LIST } from '../../graphql/client/queries';
import { useStoreContext } from '../App/StoreProvider';
import { Error, Loading } from '../_common';
import clipboard from '../../assets/clipboard.png';

import { GetBolList_getBolList as Bol, GetBolList } from '../../types/schemaTypes';
import { Routes } from '../../utils/Routes';
import moment from 'moment';

const DashboardBOLs = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        customerList: { customer },
    } = state;

    const openBols = (): void => {
        const URL = `${window.location.origin}${Routes.BILLS_LADING.route}`;
        window.location.replace(URL);
    };

    const variables = {
        input: {
            searchString: '',
            customer,
            fromDate: null,
            toDate: null,
            bolNumber: '',
            pickupLocation: 0,
            region: '',
            wellName: '',
            scaleTicket: '',
            pageSize: 10,
            offSet: 0,
            sortParam: 'shipDate',
            sortDirection: 'desc',
            poNo: '',
            invoiceNumber: '',
        },
    };
    return (
        <Query query={GET_ALL_BOL_LIST} variables={variables} skip={customer === 0} fetchPolicy="network-only">
            {({ error, data, loading }: QueryResult<GetBolList | undefined>): JSX.Element | null => {
                const bolList: Bol[] = [];
                if (data && data.getBolList && data.getBolList.length !== 0) {
                    // totalRowsCount = data.getBolList[0].totalRecordsCount;
                    data.getBolList.forEach((element: Bol | null): void => {
                        if (element !== null) {
                            bolList.push(element);
                        }
                    });
                }

                return (
                    <div className="Bols-Container">
                        {customer != 0 && error && <Error error={error} />}
                        <div>
                            <label className="Bols-Headings">My Bill of Ladings</label>
                            &nbsp;&nbsp;
                            <img alt="" src={clipboard} className="Logo" />
                        </div>
                        <label className="Label-Class">Find the most recent Bill of Ladings below:</label>
                        <div className="BOL-Table-Container">
                            {customer != 0 && <div className="Loading-Class">{loading && <Loading />}</div>}
                            <table className="BOL-Table">
                                <thead className="BOL-Table-Head">
                                    <tr className="BOL-Table-Rows">
                                        <th className="BOL-Table-Heading">BOL Number</th>
                                        <th className="BOL-Table-Heading">Location</th>
                                        <th className="BOL-Table-Heading">Qty. shipped</th>
                                        <th className="BOL-Table-Heading">UoM</th>
                                        <th className="BOL-Table-Heading">Product</th>
                                        <th className="BOL-Table-Heading">Ship Date</th>
                                    </tr>
                                </thead>
                                <tbody className="BOL-Table-Body">
                                    {bolList.map((info, index) => (
                                        <tr className="BOL-Table-Rows" key={index}>
                                            <td className="BOL-Table-Values">{info.bolNo}</td>
                                            <td className="BOL-Table-Values">{info.pickUpLocation}</td>
                                            <td className="BOL-Table-Values">{info.quantityShipped}</td>
                                            <td className="BOL-Table-Values">{info.uom}</td>
                                            <td className="BOL-Table-Values">{info.product}</td>
                                            <td className="BOL-Table-Values">{info.shipDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="BOL-Button-class">
                            <button className="BOL-Dashboard-button" onClick={openBols}>
                                See All Bill of Ladings
                            </button>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default DashboardBOLs;
