import './Filter.scss';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

import * as ReportAction from '../../store/actions/report';
import { useStoreContext } from '../App/StoreProvider';
import {
    GetReportPickupLoction_getReportPickupLoction as ReportPickupLocation,
    GetReportPickupLoction,
    GetReportProductList,
    GetReportProductList_getReportProductList as ReportProduct,
} from '../../types/schemaTypes';
import { GET_ALL_REPORT_PRODUCT, GET_ALL_REPORT_PICKUPLOCATION } from '../../graphql/client/queries';
import { Query, QueryResult } from 'react-apollo';

interface JobFiltersProps {
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const ReportsFilters = ({ abortRequest, onFilterToggle }: JobFiltersProps): JSX.Element => {
    const [activeKey, setActiveKey] = useState('0');
    const [state, dispatch] = useStoreContext();
    const search: React.Ref<HTMLInputElement> = React.createRef();
    const searchPO: React.Ref<HTMLInputElement> = React.createRef();
    const {
        report: { fromDate, toDate, poNumber, product, pickupLocation },
        customerList: { customer },
    } = state;
    const [filter_searchString, setSearchString] = useState('');
    // const [filter_invoiceStatus, setInvoiceStatus] = useState(invoiceStatus);
    const [filter_poNumber, setPONumber] = useState(poNumber);
    const [filter_fromDate, setFromDate] = useState(fromDate);
    const [filter_toDate, setToDate] = useState(toDate);
    const [filter_product, setProduct] = useState(product);
    const [filter_pickUpLocation, setPickupLocation] = useState(pickupLocation);
    // const [filter_invoiceNumber, setinvoiceNumber] = useState(invoiceNumber);
    const [isApplyFilterClicked, setIsApplyFilterClicked] = useState(false);

    useEffect(() => {
        console.log('useEffect - customer', customer);
        setSearchString('');
        setProduct('');
        setPickupLocation('');
    }, [customer]);

    // useEffect(() => {
    //     setSearchString(searchString);
    // }, [searchString]);

    useEffect(() => {
        setProduct(product);
    }, [product]);

    useEffect(() => {
        setPONumber(poNumber);
    }, [poNumber]);

    useEffect(() => {
        setFromDate(fromDate);
    }, [fromDate]);

    useEffect(() => {
        setToDate(toDate);
    }, [toDate]);

    useEffect(() => {
        setPickupLocation(pickupLocation);
    }, [pickupLocation]);

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        document.body.classList.toggle('hide-sidebar', isOpen);
    }, [isOpen]);

    const onInvoiceStatusChanges = (e): void => {
        console.log('onInvoiceStatusChanges', e.target.value);
        // setInvoiceStatus(Number(e.target.value));
        // dispatch(InvoiceActions.setFilterValue(e.target.value, InvoiceActions.Actions.FILTER_INVOICESTATUS_SELECT));
    };

    const applyFilters = () => {
        setIsApplyFilterClicked(true);
        if (filter_fromDate && filter_toDate) {
            dispatch(
                ReportAction.setFilterValue(
                    {
                        searchString: filter_searchString,
                        fromDate: filter_fromDate,
                        toDate: filter_toDate,
                        poNumber: filter_poNumber,
                        product: filter_product,
                        pickupLocation: filter_pickUpLocation,
                        // invoiceNumber: filter_invoiceNumber,
                        // invoiceStatus: Number(filter_invoiceStatus)
                    },
                    ReportAction.Actions.FILTER_ACTION_APPLYFILTER,
                ),
            );
        }
    };

    const resetFilters = () => {
        setIsApplyFilterClicked(false);
        setSearchString('');
        setProduct('');
        setPickupLocation('');
        setPONumber('');
        setFromDate(null);
        setToDate(null);
    };

    const onFromDateChange = (e): void => {
        console.log('onFromDateChange', e.target.value);
        // if (e.target.value !== '') {
        setFromDate(e.target.value);
        // dispatch(InvoiceActions.setFilterValue(e.target.value, InvoiceActions.Actions.FILTER_FROMDATE_SELECT));
        // }
    };

    const onToDateChange = (e): void => {
        console.log('onToDateChange', e.target.value);
        // if (e.target.value !== '') {
        setToDate(e.target.value);
        // dispatch(InvoiceActions.setFilterValue(e.target.value, InvoiceActions.Actions.FILTER_TODATE_SELECT));
        // }
    };

    const variables = {
        input: {
            // searchString,
            customer,
            fromDate,
            toDate,
        },
    };

    const onPOChange = (e): void => {
        console.log('filter_searchPhrase', e.target.value);
        setPONumber(e.target.value);
    };

    const onProductChanges = (e): void => {
        console.log('onProductChanges', e.target.value);
        setProduct(e.target.value);
        // dispatch(PurchaseOrderActions.setProduct(e.target.value));
    };

    const onPickUpLocationChanges = (e): void => {
        console.log('onPickUpLocationChanges', e.target.value);
        setPickupLocation(e.target.value);
        // dispatch(PurchaseOrderActions.setpickupLocation(e.target.value));
    };

    const onSearchChange = (e): void => {
        console.log('onSearchChange', e.target.value);
        setSearchString(e.target.value);
    };

    return (
        <div id="jobsFilterContainer" className="JobManagement-Filter col-md-3 pr-0">
            <div className="Filter-Container">
                {/*
                <FilterItem
                    abortRequest={abortRequest}
                    title="Purchase Orders Inquiry"
                    items={statusFilters}
                    activeKey={activeKey}
                    isCollection={true}
                    isSearchable={true}
                    onFilterToggle={onFilterToggle}
                />
                */}

                <div>
                    <div className="accordion">
                        <div className="FilterItem-Card card">
                            <div className="CustomToggle-Container">
                                <div className="CustomToggle-Header">
                                    <p className="CustomToggle-Title">Reports Inquiry</p>
                                    <Button
                                        className="Filter-SelectAll"
                                        variant="link"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <i className="material-icons">swap_horiz</i>
                                    </Button>
                                </div>
                            </div>
                            <div className="CustomToggle-Divider"></div>
                            <div className="collapse show">
                                <div className="FilterItem-Body FilterItem-BodyPadding card-body">
                                    <div className="SearchBar-Container">
                                        <i className="material-icons SearchBar-Icon">search</i>
                                        <input
                                            id="textSearch"
                                            type="text"
                                            placeholder="Search"
                                            className="SearchBar-Input"
                                            autoComplete="off"
                                            defaultValue=""
                                            value={filter_searchString}
                                            onChange={e => onSearchChange(e)}
                                        />
                                    </div>
                                    <div className="row pt-3"></div>

                                    <div className="row pt-3">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>PO Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchPO}
                                                    value={filter_poNumber}
                                                    //defaultValue={poNo}
                                                    onChange={e => onPOChange && onPOChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Product</label>
                                                {customer !== 0 ? (
                                                    <Query
                                                        query={GET_ALL_REPORT_PRODUCT}
                                                        variables={variables}
                                                        fetchPolicy="network-only"
                                                    >
                                                        {({
                                                            error,
                                                            data,
                                                            loading,
                                                            refetch,
                                                            fetchMore,
                                                        }: QueryResult<
                                                            GetReportProductList | undefined
                                                        >): JSX.Element | null => {
                                                            const reportProductList: ReportProduct[] = [];
                                                            const totalRowsCount = 0;
                                                            // if (error) {
                                                            //     return <Error error={error} />;
                                                            // }
                                                            if (
                                                                data &&
                                                                data.getReportProductList &&
                                                                data.getReportProductList.length !== 0
                                                            ) {
                                                                data.getReportProductList.forEach(
                                                                    (element: ReportProduct | null): void => {
                                                                        if (element !== null) {
                                                                            // totalRowsCount = Number(element.totalRecordsCount);
                                                                            reportProductList.push(element);
                                                                        }
                                                                    },
                                                                );
                                                                console.log('reportProductList', reportProductList);
                                                            }
                                                            return (
                                                                <select
                                                                    className="form-control"
                                                                    value={filter_product}
                                                                    onChange={e => onProductChanges(e)}
                                                                >
                                                                    <option value={''}>All</option>
                                                                    {reportProductList.map((prd, index) => (
                                                                        <option
                                                                            key={prd.productNumber?.toString()}
                                                                            value={prd.productNumber?.toString()}
                                                                        >
                                                                            {prd.productName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            );
                                                        }}
                                                    </Query>
                                                ) : (
                                                    <select className="form-control">
                                                        <option>All</option>
                                                    </select>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Pick Up Location</label>
                                                {customer !== 0 ? (
                                                    <Query
                                                        query={GET_ALL_REPORT_PICKUPLOCATION}
                                                        variables={variables}
                                                        fetchPolicy="network-only"
                                                    >
                                                        {({
                                                            error,
                                                            data,
                                                            loading,
                                                            refetch,
                                                            fetchMore,
                                                        }: QueryResult<
                                                            GetReportPickupLoction | undefined
                                                        >): JSX.Element | null => {
                                                            const reportPickupLocation: ReportPickupLocation[] = [];
                                                            const totalRowsCount = 0;
                                                            // if (error) {
                                                            //     return <Error error={error} />;
                                                            // }
                                                            if (
                                                                data &&
                                                                data.getReportPickupLoction &&
                                                                data.getReportPickupLoction.length !== 0
                                                            ) {
                                                                data.getReportPickupLoction.forEach(
                                                                    (element: ReportPickupLocation | null): void => {
                                                                        if (element !== null) {
                                                                            // totalRowsCount = Number(element.totalRecordsCount);
                                                                            reportPickupLocation.push(element);
                                                                        }
                                                                    },
                                                                );
                                                                console.log(
                                                                    'reportPickupLocation',
                                                                    reportPickupLocation,
                                                                );
                                                            }
                                                            return (
                                                                <select
                                                                    className="form-control"
                                                                    value={filter_pickUpLocation}
                                                                    onChange={e => onPickUpLocationChanges(e)}
                                                                >
                                                                    <option value={''}>All</option>
                                                                    {reportPickupLocation.map((prd, index) => (
                                                                        <option
                                                                            key={prd.pickUpLocationId?.toString()}
                                                                            value={prd.pickUpLocationId?.toString()}
                                                                        >
                                                                            {prd.pickUpLocation}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            );
                                                        }}
                                                    </Query>
                                                ) : (
                                                    <select className="form-control">
                                                        <option>All</option>
                                                    </select>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Date From</label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${
                                                        isApplyFilterClicked && !filter_fromDate
                                                            ? 'Filter-Error-Required'
                                                            : ''
                                                    }`}
                                                    value={filter_fromDate ? filter_fromDate : ''}
                                                    onChange={e => onFromDateChange(e)}
                                                />
                                                {isApplyFilterClicked && !filter_fromDate && (
                                                    <label style={{ color: 'red' }}>Date from is required</label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Date To</label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${
                                                        isApplyFilterClicked && !filter_toDate
                                                            ? 'Filter-Error-Required'
                                                            : ''
                                                    }`}
                                                    value={filter_toDate ? filter_toDate : ''}
                                                    onChange={e => onToDateChange(e)}
                                                />
                                                {isApplyFilterClicked && !filter_toDate && (
                                                    <label style={{ color: 'red' }}>Date to is required</label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button className="btn_clear" onClick={resetFilters}>
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button className="btn_apply" onClick={applyFilters}>
                                                    Apply Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
