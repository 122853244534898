/* eslint-disable */
import React, { FC, useContext, useEffect } from 'react';
import { UserManager, UserManagerSettings, WebStorageStateStore, User } from 'oidc-client';
import * as AuthActions from '../../../store/actions/auth';
import { useStoreContext } from '../../App/StoreProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';

const config: UserManagerSettings = {
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    authority: process.env.REACT_APP_AUTHORITY_URL,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    automaticSilentRenew: true,
    monitorSession: true,
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_URL,
    response_type: 'id_token token',
    scope: 'openid profile OperationsPortal.API OperationsPortal.User'
};

const _userManager = new UserManager(config);

const AuthContext = React.createContext({
    userManager: _userManager,
    userHasPermission: (permissionType: PermissionEnum): boolean => false,
});

const AuthConsumer = AuthContext.Consumer;

const useAuthContext = (): {
    userManager: UserManager;
    userHasPermission: (permissionType: PermissionEnum) => boolean;
} => useContext(AuthContext);

interface AuthProviderProps {
    children: JSX.Element;
}

const AuthProvider: FC<AuthProviderProps> = (props: AuthProviderProps): JSX.Element => {
    const { children } = props;
    const userManager = _userManager;
    const [{ auth }, dispatch] = useStoreContext();

    const userHasPermission = (permissionType: PermissionEnum): boolean => {
        let hasPermission;

        try {
            if (auth.user) {
                var opsPersm =  auth!.user!.profile!.role;
               // var perms = JSON.parse(auth!.user!.profile!.permissions);
                hasPermission = opsPersm.includes(permissionType.toString());
            }
        } catch (e) {
           // console.log(e.message);
        }

        return hasPermission;
    };

    const loadUser = () => {
        userManager.getUser().then(user => {
            console.log('load user', user);
            if (user) {
                dispatch(AuthActions.userLoaded(user as User));
            } else {
                dispatch(AuthActions.userLoading(false));  
            }
        });
    };

    const onUserLoaded = (user: User): void => {
        console.log('onUserLoaded', user);
        // console.log('onUserLoaded - role', JSON.parse(user));
        // console.log('onUserLoaded - role', user.role);
        dispatch(AuthActions.userLoaded(user));
        sessionStorage.setItem('accessToken', user.access_token);
    };

    const onSilentRenewError = (e: any): void => {
        dispatch(AuthActions.silentRenewError(e));
    };

    const onAccessTokenExpired = (): void => {
        dispatch(AuthActions.accessTokenExpired());
        userManager.removeUser();
    };

    const onUserUnloaded = (): void => {
        dispatch(AuthActions.userUnloaded());
        sessionStorage.removeItem('accessToken');
    };

    const onUserSignedOut = (): void => {
        dispatch(AuthActions.userSignedOut());
        userManager.removeUser();
        sessionStorage.removeItem('accessToken');
        window.location.href = window.location.origin;
    };

    useEffect(() => {
        loadUser();
        userManager.events.addUserLoaded(onUserLoaded);
        userManager.events.addSilentRenewError(onSilentRenewError);
        userManager.events.addAccessTokenExpired(onAccessTokenExpired);
        userManager.events.addUserUnloaded(onUserUnloaded);
        userManager.events.addUserSignedOut(onUserSignedOut);

        return () => {
            userManager.events.removeUserLoaded(onUserLoaded);
            userManager.events.removeSilentRenewError(onSilentRenewError);
            userManager.events.removeAccessTokenExpired(onAccessTokenExpired);
            userManager.events.removeUserUnloaded(onUserUnloaded);
            userManager.events.removeUserSignedOut(onUserSignedOut);
        };
    }, []);

    return <AuthContext.Provider value={{ userManager, userHasPermission }}>{children}</AuthContext.Provider>;
};

export { AuthConsumer, useAuthContext, AuthProvider };
