import React from 'react';
import './DashboardDetails.scss';
import { Query, QueryResult, useQuery } from 'react-apollo';
import { GET_ALL_CUSTOMERSLIST, GET_ALL_PURCHASEORDER_LIST, GET_CUSTOMERS_CONTACT } from '../../graphql/client/queries';
import { PermissionEnum } from '../../types/PermissionEnum';
import * as CustomerListActions from '../../store/actions/customerList';
import { useStoreContext } from '../App/StoreProvider';
import { useAuthContext } from '../Auth/AuthProvider';
import {
    GetCustomersContact,
    GetCustomersContact_getCustomersContact as CustomerContact,
    GetCustomersContact_getCustomersContact_electronicDetails as ElectronicAddress,
} from '../../types/schemaTypes';
import { Error, Loading } from '../_common';

const DashboardDetails = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const authContext = useAuthContext();
    const {
        auth: { user },
        customerList: { customerList, customer },
    } = state;

    const opsCustomer = authContext.userHasPermission(PermissionEnum.Customer);
    let selectedCustomer = 0;
    const userProfile = user.profile;
    if (opsCustomer && customer === 0 && userProfile && userProfile.role === 'Customer') {
        const userProfile = user.profile;
        const customerId = userProfile.Customer;
        selectedCustomer = Number(customerId);
        dispatch(CustomerListActions.setCustomerID(selectedCustomer));
    }

    const input = {
        search: '',
        customerId: opsCustomer ? selectedCustomer : 0,
    };

    const { data } = useQuery(GET_ALL_CUSTOMERSLIST, {
        variables: { input },
        skip:
            customerList.length > 0 ||
            (opsCustomer && customer === 0 && userProfile && userProfile.role === 'Customer'),
    });

    if (customerList.length === 0 && data && data.getAllCustomerList && data.getAllCustomerList.length !== 0) {
        const customerusersData = data.getAllCustomerList;
        dispatch(CustomerListActions.setCustomerList(customerusersData));
    }

    const variables = {
        input: {
            customerId: customer,
        },
    };
    return (
        <Query query={GET_CUSTOMERS_CONTACT} variables={variables} skip={customer === 0} fetchPolicy="network-only">
            {({ error, data, loading }: QueryResult<GetCustomersContact | undefined>): JSX.Element | null => {
                return (
                    <div className="Dashboard-Details-Container">
                        {customer != 0 && <div className="Loading-Class">{loading && <Loading />}</div>}
                        {!loading && (
                            <div className="Row">
                                <div className="Column">
                                    <label className="Details-Headings">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Details</label>
                                    <div className="Details-Table">
                                        <div className="Details-Keys">Parent Number</div>
                                        <div className="Details-Values">{customer}</div>
                                        <div className="Details-Keys">Address</div>
                                        <div className="Details-Values">{data?.getCustomersContact?.address}</div>
                                        <div className="Details-Keys">City, State & Zip</div>
                                        <div className="Details-Values">
                                            {data?.getCustomersContact?.city}
                                            {data?.getCustomersContact?.state
                                                ? `, ${data.getCustomersContact.state}`
                                                : ''}
                                            {data?.getCustomersContact?.postalCode
                                                ? ` & ${data.getCustomersContact.postalCode}`
                                                : ''}
                                        </div>
                                        <div className="Details-Keys">Country & County</div>
                                        <div className="Details-Values">
                                            {data?.getCustomersContact?.country}
                                            {data?.getCustomersContact?.county
                                                ? `, ${data?.getCustomersContact?.county}`
                                                : ''}
                                        </div>
                                        <div className="Details-Keys">Electronic Addresses</div>
                                        <div className="Details-Values">
                                            {data?.getCustomersContact?.electronicDetails?.map(info => (
                                                <label className="address-label" key={info?.emailType}>
                                                    {info?.emailType}
                                                    <br></br>
                                                    {info?.emailAddress}
                                                </label>
                                            ))}
                                        </div>
                                        <div className="Details-Keys">Payment Terms</div>
                                        <div className="Details-Values">
                                            {data?.getCustomersContact?.paymentTerms
                                                ? `Net ${data.getCustomersContact.paymentTerms} Days`
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="vertical_dotted_line"></div>
                                <div className="Column">
                                    <label className="Details-Headings">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contacts</label>
                                    <div className="Details-Table">
                                        <div className="Details-Keys">Sales Representative</div>
                                        <div className="Details-Values">
                                            {data?.getCustomersContact?.contactDetails?.salesRepName}
                                            <br></br>
                                            &nbsp;&nbsp;
                                            {data?.getCustomersContact?.contactDetails?.salesRepEmail}
                                            <br></br>
                                            &nbsp;&nbsp;
                                            {data?.getCustomersContact?.contactDetails?.salesRepPhoneNumber}
                                        </div>

                                        <div className="Details-Keys">Customer Service</div>
                                        <div className="Details-Values">
                                            {data?.getCustomersContact?.contactDetails?.custServiceName}
                                            <br></br>
                                            &nbsp;&nbsp;
                                            {data?.getCustomersContact?.contactDetails?.custServiceEmail}
                                            <br></br>
                                            &nbsp;&nbsp;
                                            {data?.getCustomersContact?.contactDetails?.custServicePhoneNumber}
                                        </div>
                                        <div className="Details-Keys">Credit Analyst</div>
                                        <div className="Details-Values">
                                            {data?.getCustomersContact?.contactDetails?.creditAnalystName}
                                            <br></br>
                                            &nbsp;&nbsp;
                                            {data?.getCustomersContact?.contactDetails?.creditAnalystEmail}
                                            <br></br>
                                            &nbsp;&nbsp;
                                            {data?.getCustomersContact?.contactDetails?.creditAnalystPhoneNumber}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }}
        </Query>
    );
};

export default DashboardDetails;
