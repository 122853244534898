import { Action } from '../../types';

export enum Actions {
    UPDATE_SEARCH_PHRASE = 'PURCHASEORDER_UPDATE_SEARCH_PHRASE',
    FILTER_CUSTOMER_SELECT = 'PURCHASEORDER_FILTER_CUSTOMER_SELECT',
    FILTER_FROMDATE_SELECT = 'PURCHASEORDER_FILTER_FROMDATE_SELECT',
    FILTER_TODATE_SELECT = 'PURCHASEORDER_FILTER_TODATE_SELECT',
    FILTER_PRODUCT_SELECT = 'PURCHASEORDER_FILTER_PRODUCT_SELECT',
    FILTER_PICKUPLOCATION_SELECT = 'PURCHASEORDER_FILTER_PICKUPLOCATION_SELECT',
    FILTER_PO = 'PURCHASEORDER_FILTER_PO',
    FILTER_ACTION_APPLYFILTER = 'PURCHASEORDER_FILTER_ACTION_APPLYFILTER',
    FILTER_ACTION_RESETFILTER = 'PURCHASEORDER_FILTER_ACTION_RESETFILTER',
}

export const setSearchPhrase = (searchPhrase: string): Action => {
    console.log('purchaseOrderAction - setSearchPhrase', searchPhrase);
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};

export const setSearchPO = (poNo: string): Action => {
    console.log('purchaseOrderAction - setSearchPO', poNo);
    return {
        type: Actions.FILTER_PO,
        payload: poNo,
        error: null,
    };
};

export const setFromDate = (fromDate: string): Action => {
    console.log('purchaseOrderAction - setFromDate', fromDate);
    return {
        type: Actions.FILTER_FROMDATE_SELECT,
        payload: fromDate,
        error: null,
    };
};

export const setToDate = (toDate: string): Action => {
    console.log('purchaseOrderAction - setToDate', toDate);
    return {
        type: Actions.FILTER_TODATE_SELECT,
        payload: toDate,
        error: null,
    };
};

export const setProduct = (productNo: string): Action => {
    console.log('purchaseOrderAction - setProduct', productNo);
    return {
        type: Actions.FILTER_PRODUCT_SELECT,
        payload: productNo,
        error: null,
    };
};

export const setpickupLocation = (pickupLocation: string): Action => {
    console.log('purchaseOrderAction - setpickupLocation', pickupLocation);
    return {
        type: Actions.FILTER_PICKUPLOCATION_SELECT,
        payload: pickupLocation,
        error: null,
    };
};

export const setCustomerSelect = (custometId: number): Action => {
    console.log('purchaseOrderAction - setCustomerSelect', setCustomerSelect);
    return {
        type: Actions.FILTER_CUSTOMER_SELECT,
        payload: custometId,
        error: null,
    };
};

export const applyFilters = (filter): Action => {
    console.log('purchaseOrderAction - applyFilters', setCustomerSelect);
    return {
        type: Actions.FILTER_ACTION_APPLYFILTER,
        payload: filter,
        error: null,
    };
};

export const resetFilters = (): Action => {
    console.log('purchaseOrderAction - setCustomerSelect', setCustomerSelect);
    return {
        type: Actions.FILTER_ACTION_RESETFILTER,
        payload: '',
        error: null,
    };
};
