import gql from 'graphql-tag';

export default gql`
    query GetPurchaseOrderList($input: GetAllPurchaseOrderInput) {
        getPurchaseOrderList(input: $input) {
            customerPONumber
            orderNo
            dateEntered
            shipTo
            shipToName
            status
            customerPODate
            pickUpLocationNumber
            pickUpLocation
            customerNumber
            customer
            productNumber
            productName
            quantityonPO
            quantityShipped
            uom
            quantityRemaining
            totalRecordsCount
            poValue
        }
    }
`;
