import { Reducer } from 'react';
import { Actions } from '../../actions/userList';
import { Action, UserListState } from '../../types';

const initialUserListState: UserListState = {
    searchPhrase: '',
    customerId: 0,
};

const setSearchPhrase = (search: string, state: UserListState): UserListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const setCustomerSelection = (customerId, state: UserListState): UserListState => {
    return {
        ...state,
        customerId: customerId,
    };
};

const userListReducer: Reducer<UserListState, Action> = (state, action): UserListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_CUSTOMER_SELECTION:
            newState = setCustomerSelection(action.payload, state);
            break;
    }

    return newState;
};

export { initialUserListState, userListReducer };
