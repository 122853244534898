import gql from 'graphql-tag';

export default gql`
    query GetReport($input: GetReportInput) {
        getReport(input: $input) {
            shipToName
            poNumber
            pickupLocation
            bolNumber
            shipToNumber
            shipToName
            alternateBOLNumber
            invoiceNumber
            vehicleNumber
            wellName
            quantityLoaded
            quantityRemaining
            transactionUOM
            grossWeight
            tareWeight
            netWeight
            totalRecordsCount
        }
    }
`;
