import './Filter.scss';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Error, Loading } from '../_common';
import { Query, QueryResult } from 'react-apollo';
import { useStoreContext } from '../App/StoreProvider';
import * as InvoiceActions from '../../store/actions/invoice';

interface JobFiltersProps {
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const OpenStatementAcctFilters = ({ abortRequest, onFilterToggle }: JobFiltersProps): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        invoice: { searchString, invoiceStatus, poNumber, invoiceNumber },
        customerList: { customer },
    } = state;
    const search: React.Ref<HTMLInputElement> = React.createRef();
    const searchPO: React.Ref<HTMLInputElement> = React.createRef();
    const searchInvoice: React.Ref<HTMLInputElement> = React.createRef();

    const [filter_searchString, setSearchString] = useState(searchString);
    const [filter_poNumber, setPONumber] = useState(poNumber);
    const [filter_invoiceNumber, setinvoiceNumber] = useState(invoiceNumber);
    const [isApplyFilterClicked, setIsApplyFilterClicked] = useState(false);

    useEffect(() => {
        console.log('useEffect - customer', customer);
        setSearchString('');
        setPONumber('');
    }, [customer]);

    useEffect(() => {
        setSearchString(searchString);
    }, [searchString]);

    useEffect(() => {
        setPONumber(poNumber);
    }, [poNumber]);

    const updateSearchPhrase = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const searchPhrase = search && search.current ? search.current.value : '';
            // dispatch(InvoiceActions.setFilterValue(searchPhrase, InvoiceActions.Actions.FILTER_SEARCHSTRING));
        }
    };

    const onSearchChange = (e): void => {
        console.log('onSearchChange', e.target.value);
        setSearchString(e.target.value);
    };

    const updateSearchPO = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const search = searchPO && searchPO.current ? searchPO.current.value : '';
            // dispatch(InvoiceActions.setFilterValue(search, InvoiceActions.Actions.FILTER_PONUMBER));
        }
    };

    const onPOChange = (e): void => {
        console.log('onPOChange', e.target.value);
        setPONumber(e.target.value);
    };

    const updateSearchInvoice = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const search = searchInvoice && searchInvoice.current ? searchInvoice.current.value : '';
            // dispatch(InvoiceActions.setFilterValue(search, InvoiceActions.Actions.FILTER_INVOICE));
        }
    };

    const onInvoiceChange = (e): void => {
        console.log('onInvoiceChange', e.target.value);
        setinvoiceNumber(e.target.value);
    };

    const onChange = (e): void => {
        console.log('onChange', e.target.value);
    };

    const [activeKey, setActiveKey] = useState('0');

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        document.body.classList.toggle('hide-sidebar', isOpen);
    }, [isOpen]);

    const applyFilters = () => {
        setIsApplyFilterClicked(true);
        dispatch(
            InvoiceActions.setFilterValue(
                {
                    searchString: filter_searchString,
                    poNumber: filter_poNumber,
                    invoiceNumber: filter_invoiceNumber,
                    invoiceStatus: 1,
                },
                InvoiceActions.Actions.FILTER_INVOICE_ACTION_APPLYFILTER,
            ),
        );
    };

    const resetFilters = () => {
        setIsApplyFilterClicked(false);
        setSearchString('');
        setPONumber('');
        // dispatch(InvoiceActions.setFilterValue('', InvoiceActions.Actions.FILTER_INVOICE_ACTION_RESETFILTER));
    };

    return (
        <div id="jobsFilterContainer" className="JobManagement-Filter col-md-3 pr-0">
            <div className="Filter-Container">
                <div>
                    <div className="accordion">
                        <div className="FilterItem-Card card">
                            <div className="CustomToggle-Container">
                                <div className="CustomToggle-Header">
                                    <p className="CustomToggle-Title">Invoice Inquiry</p>
                                    <Button
                                        className="Filter-SelectAll"
                                        variant="link"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <i className="material-icons">swap_horiz</i>
                                    </Button>
                                </div>
                            </div>
                            <div className="CustomToggle-Divider"></div>
                            <div className="collapse show">
                                <div className="FilterItem-Body FilterItem-BodyPadding card-body">
                                    <div className="SearchBar-Container">
                                        <i className="material-icons SearchBar-Icon">search</i>
                                        <input
                                            id="textSearch"
                                            type="text"
                                            placeholder="Search"
                                            className="SearchBar-Input"
                                            autoComplete="off"
                                            ref={search}
                                            defaultValue=""
                                            value={filter_searchString}
                                            onChange={e => onSearchChange(e)}
                                            onKeyDown={(e): void => updateSearchPhrase(e)}
                                        />
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>PO Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchPO}
                                                    value={filter_poNumber}
                                                    onChange={e => onPOChange(e)}
                                                    onKeyDown={(e): void => updateSearchPO(e)}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Address Number</label>
                                                <select className="form-control" >
                                                    <option>All</option>

                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Invoice Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchInvoice}
                                                    value={filter_invoiceNumber}
                                                    onChange={e => onInvoiceChange(e)}
                                                    onKeyDown={(e): void => updateSearchInvoice(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button className="btn_clear" onClick={resetFilters}>
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button className="btn_apply" onClick={applyFilters}>
                                                    Apply Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
