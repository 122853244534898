import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './OpenStatementAcctHeader.scss';

import { useStoreContext } from '../../App/StoreProvider';
import CustomerDropDown from '../CustomerDropDown';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

const OpenStatementAcctHeader = ({ onDownloadOpenInvoices, onPrintOpenInvoices }: any): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        invoice: { searchString, fromDate, toDate, addressNumber, invoiceStatus, poNumber },
        customerList: { customer },
    } = state;

    const onDownloadExcel = (e): void => {
        const queryparams = `?Customer=${customer}&SearchString=${searchString}&FromDate=${
            fromDate ? fromDate : ''
        }&Todate=${toDate ? toDate : ''}&PageSize=0&poNumber=${poNumber}&invoiceStatus=${invoiceStatus}`;
        window.open(`${process.env.REACT_APP_API_URL}OpenStatementAcct/ExportOpenInvoice${queryparams}`, '_blank');
    };

    return (
        <div className="I-Header-Container">
            <div className="I-Header-SearchBar">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-3 my-auto">
                                <label htmlFor="staticEmail">Customer</label>
                            </div>
                            <div className="col-md-6">
                                <CustomerDropDown></CustomerDropDown>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 text-right">
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadExcel}>
                            Download to Excel
                        </button>
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadOpenInvoices}>
                            Download Invoice
                        </button>
                        {/* <button className="Button CustomButton-Plain mx-1" onClick={onPrintOpenInvoices}>
                            Print Invoices
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="Header-ListHeader"></div>
        </div>
    );
};

export default OpenStatementAcctHeader;
