import { Action } from '../../types';

export enum Actions {
    FILTER_SEARCHSTRING = 'INVOICE_FILTER_SEARCHSTRING',
    FILTER_CUSTOMER_SELECT = 'INVOICE_FILTER_CUSTOMER_SELECT',
    FILTER_INVOICESTATUS_SELECT = 'INVOICE_FILTER_STATUS_SELECT',
    FILTER_FROMDATE_SELECT = 'INVOICE_FILTER_FROMDATE_SELECT',
    FILTER_TODATE_SELECT = 'INVOICE_FILTER_TODATE_SELECT',
    FILTER_PONUMBER = 'INVOICE_FILTER_PONUMBER',
    FILTER_ADDRESSNUMBER = 'INVOICE_FILTER_ADDRESSNUMBER',
    FILTER_INVOICE = 'INVOICE_FILTER_INVOICE',
    FILTER_INVOICE_ACTION_APPLYFILTER = 'INVOICE_ACTION_APPLYFILTER',
    FILTER_INVOICE_ACTION_RESETFILTER = 'INVOICE_ACTION_RESETFILTER',
}

export const setFilterValue = (value: any, actionName: any): Action => {
    console.log('setFilterValue - Value , ActionANme', value, actionName);
    return {
        type: actionName,
        payload: value,
        error: null,
    };
};
