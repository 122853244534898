import gql from 'graphql-tag';

export default gql`
    query GetAllCustomerList($input: CustomerListInput) {
        getAllCustomerList(input: $input) {
            id
            customerName
        }
    }
`;
