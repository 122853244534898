import React from 'react';
import key from '../../assets/key.png';
import Inquiry from '../../assets/question.png';
import openStatement from '../../assets/OpenStatement.png';
import payment from '../../assets/coins.png';
import dispute from '../../assets/stop.png';
import customerInquiry from '../../assets/elearning.png';
import contactInfo from '../../assets/agenda.png';
import './DashboardLinks.scss';
import { Routes } from '../../utils/Routes';

const DashboardLinks = (): JSX.Element => {
    const openPurchaseOrders = (): void => {
        const URL = `${window.location.origin}${Routes.PURCHASE_ORDERS.route}`;
        window.location.replace(URL);
    };

    const openContactPage = (): void => {
        const URL = `${window.location.origin}${Routes.CONTACT.route}`;
        window.location.replace(URL);
    };

    const openStatementPage = (): void => {
        const URL = `${window.location.origin}${Routes.OPEN_STATEMENT_ACCT.route}`;
        window.location.replace(URL);
    };

    return (
        <div className="Dashboard-LinksContainer">
            <div>
                <label className="Dashboard-Links-Headings">Quick Access Links</label>
                &nbsp;&nbsp;
                <img alt="" src={key} className="Logo" />
            </div>
            <label className="Label-Class">Click the Links below for quick access:</label>
            <br></br>
            <div className="row">
                <div className="col-md-1">
                    <button className="DashboardLinks-button-class" onClick={openPurchaseOrders}>
                        <img alt="" src={Inquiry} className="Logo1" />
                    </button>
                    <label className="DashboardLink-Label"> Order Management Inquiry</label>
                </div>
                <div className="col-md-1">
                    <button className="DashboardLinks-button-class" onClick={openStatementPage}>
                        <img alt="" src={openStatement} className="Logo1" />
                    </button>
                    <label className="DashboardLink-Label"> Open Statement of Account</label>
                </div>
                <div className="col-md-1">
                    <button className="DashboardLinks-button-class" onClick={openContactPage}>
                        <img alt="" src={payment} className="Logo1" />
                    </button>
                    <label className="DashboardLink-Label"> Payments or Remittance Inquiry</label>
                </div>
                <div className="col-md-1">
                    <button className="DashboardLinks-button-class" onClick={openContactPage}>
                        <img alt="" src={dispute} className="Logo1" />
                    </button>
                    <label className="DashboardLink-Label">Dispute an Invoice</label>
                </div>
                <div className="col-md-1">
                    <button className="DashboardLinks-button-class" onClick={openContactPage}>
                        <img alt="" src={customerInquiry} className="Logo1" />
                    </button>
                    <label className="DashboardLink-Label"> Customer Portal Inquiry</label>
                </div>
                <div className="col-md-1">
                    <button className="DashboardLinks-button-class" onClick={openContactPage}>
                        <img alt="" src={contactInfo} className="Logo1" />
                    </button>
                    <label className="DashboardLink-Label"> Update Contact Information</label>
                </div>
            </div>
        </div>
    );
};

export default DashboardLinks;
