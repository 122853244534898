import { Action } from '../../types';

export enum Actions {
    FILTER_SEARCHSTRING = 'BOL_FILTER_SEARCHSTRING',
    FILTER_CUSTOMER_SELECT = 'BOL_FILTER_CUSTOMER_SELECT',
    FILTER_FROMDATE_SELECT = 'BOL_FILTER_FROMDATE_SELECT',
    FILTER_TODATE_SELECT = 'BOL_FILTER_TODATE_SELECT',
    FILTER_BOLNUMBER = 'BOL_FILTER_BOLNUMBER',
    FILTER_REGION = 'BOL_FILTER_REGION',
    FILTER_PICKUPLOCATION = 'BOL_FILTER_PICKUPLOCATION',
    FILTER_WELLNAME = 'BOL_FILTER_WELLNAME',
    FILTER_PONO = 'BOL_FILTER_PONO',
    FILTER_ALTBOL = 'BOL_FILTER_ALTBOL',
    FILTER_INVOICE = 'BOL_FILTER_INVOICE',
    BOL_ONCLICK_RELATEDBOL = 'BOL_ONCLICK_RELATEDBOL',
    BOL_FILTER_ACTION_APPLYFILTER = 'BOL_FILTER_ACTION_APPLYFILTER',
    BOL_FILTER_ACTION_RESETFILTER = 'BOL_FILTER_ACTION_RESETFILTER',
}

export const setFilterValue = (value: any, actionName: any): Action => {
    console.log('setFilterValue - Value , ActionANme', value, actionName);
    return {
        type: actionName,
        payload: value,
        error: null,
    };
};
