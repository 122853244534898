import { Action } from '../../types';

export enum Actions {
    FILTER_SEARCH_STRING = 'REPORT_FILTER_SEARCH_STRING',
    FILTER_CUSTOMER_SELECT = 'REPORT_FILTER_CUSTOMER_SELECT',
    FILTER_FROMDATE_SELECT = 'REPORT_FILTER_FROMDATE_SELECT',
    FILTER_TODATE_SELECT = 'REPORT_FILTER_TODATE_SELECT',
    FILTER_PRODUCT_SELECT = 'REPORT_FILTER_PRODUCT_SELECT',
    FILTER_PICKUPLOCATION_SELECT = 'REPORT_FILTER_PICKUPLOCATION_SELECT',
    FILTER_PO = 'REPORT_FILTER_PO',
    FILTER_ACTION_APPLYFILTER = 'REPORT_FILTER_ACTION_APPLYFILTER',
    FILTER_ACTION_RESETFILTER = 'REPORT_FILTER_ACTION_RESETFILTER',
}

export const setFilterValue = (value: any, actionName: any): Action => {
    console.log('setFilterValue - Value , ActionANme', value, actionName);
    return {
        type: actionName,
        payload: value,
        error: null,
    };
};
