import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './PurchaseOrdersHeader.scss';

import { useStoreContext } from '../../App/StoreProvider';
import CustomerDropDown from '../CustomerDropDown/CustomerDropDown';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

const PurchaseOrdersHeader = ({ onLoadRelatedBols }: any): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        purchaseOrder: { searchPhrase, fromDate, toDate, product },
        customerList: { customer },
    } = state;
    const variables = {
        input: '',
    };

    // const onLoadRelatedBols = (e) => {
    //     console.log('onLoadRelatedBols');
    //     // state.history.push('/some/path')
    //     // return (<Redirect to={{ pathname: Routes.BILLS_LADING.route }}></Redirect>);
    //     // <Navigate to={Routes.BILLS_LADING.route} replace={true} />
    // }

    const onDownloadExcel = (e): void => {
        console.log('onDownloadExcel');
        console.log(
            'PurchaseOrdersHeader - customer',
            customer,
            'process.env.REACT_APP_API_URL,',
            process.env.REACT_APP_API_URL,
            'process.env.REACT_APP_AUTHORITY_URL',
            process.env.REACT_APP_AUTHORITY_URL,
        );
        const queryparams = `?Customer=${customer}&SearchString=${searchPhrase}&FromDate=${
            fromDate ? fromDate : ''
        }&Todate=${toDate ? toDate : ''}&PageSize=0&Product=${product}`;
        window.open(`${process.env.REACT_APP_API_URL}PurchaseOrder/ExportPurchaseOrder${queryparams}`, '_blank');
    };

    return (
        <div className="PO-Header-Container">
            <div className="PO-Header-SearchBar">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-3 my-auto">
                                <label htmlFor="staticEmail">Customer</label>
                            </div>
                            <div className="col-md-6">
                                <CustomerDropDown></CustomerDropDown>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 text-right">
                        <button className="Button CustomButton-Plain mx-1" onClick={onLoadRelatedBols}>
                            Related BOL
                        </button>
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadExcel}>
                            Download to Excel
                        </button>
                    </div>
                </div>
            </div>
            <div className="Header-ListHeader"></div>
        </div>
    );
};

export default PurchaseOrdersHeader;
