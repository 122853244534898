import { Reducer } from 'react';
import { Actions } from '../../actions/report';
import { Action } from '../../types';
import { Actions as CustomerAction } from '../../actions/customerList';
import moment from 'moment';
import { ReportState } from '../../types/reportState';

const currentDate = new Date();
const toDate = new Date(new Date().setDate(currentDate.getDate() + 180));
const fromDate = new Date(new Date().setDate(currentDate.getDate() - 30));
const fromDateStr = moment(fromDate).format('YYYY-MM-DD');
const toDateStr = moment(toDate).format('YYYY-MM-DD');

const initialReportState: ReportState = {
    searchString: '',
    customer: 0,
    fromDate: fromDateStr,
    toDate: toDateStr,
    poNumber: '',
    pickupLocation: '',
    product: '',
};

const reportReducer: Reducer<ReportState, Action> = (state, action): ReportState => {
    console.log('bolReducer - purchaseOrderReducer', action);
    let newState = { ...state };

    switch (action.type) {
        case Actions.FILTER_SEARCH_STRING:
            newState = { ...state, searchString: action.payload };
            break;
        case Actions.FILTER_CUSTOMER_SELECT:
            newState = { ...state, customer: Number(action.payload) };
            break;
        case Actions.FILTER_FROMDATE_SELECT:
            newState = { ...state, fromDate: action.payload };
            break;
        case Actions.FILTER_TODATE_SELECT:
            newState = { ...state, toDate: action.payload };
            break;
        case Actions.FILTER_PO:
            newState = { ...state, poNumber: action.payload };
            break;

        case Actions.FILTER_ACTION_APPLYFILTER:
            newState = {
                ...state,
                searchString: action.payload.searchString,
                fromDate: action.payload.fromDate,
                toDate: action.payload.toDate,
                poNumber: action.payload.poNumber,
                pickupLocation: action.payload.pickupLocation,
                product: action.payload.product,
            };
            break;
        case Actions.FILTER_ACTION_RESETFILTER:
            newState = {
                ...state,
                searchString: '',
                fromDate: fromDateStr,
                toDate: toDateStr,
                poNumber: '',
                pickupLocation: '',
                product: '',
            };
            break;
        case CustomerAction.UPDATE_CUSTOMER_ID:
            newState = {
                ...state,
                searchString: '',
                fromDate: fromDateStr,
                toDate: toDateStr,
                poNumber: '',
                pickupLocation: '',
                product: '',
            };
            break;
    }

    return newState;
};

export { initialReportState, reportReducer };
