import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './OpenOrdersHeader.scss';

import { useStoreContext } from '../../App/StoreProvider';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

const OpenOrdersHeader = ({ onLoadRelatedBols, showRelatedBOL, shipToName }: any): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        purchaseOrder: { searchPhrase, fromDate, toDate, product },
        customerList: { customer },
    } = state;
    const variables = {
        input: '',
    };

    // const onLoadRelatedBols = (e) => {
    //     console.log('onLoadRelatedBols');
    //     // state.history.push('/some/path')
    //     // return (<Redirect to={{ pathname: Routes.BILLS_LADING.route }}></Redirect>);
    //     // <Navigate to={Routes.BILLS_LADING.route} replace={true} />
    // }

    return (
        <div className="R-Header-Container">
            <div className="R-Header-SearchBar">
                <div className="row">
                    <div className="col-md-7">
                        <div className="row">
                            {/* <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-3 my-auto">
                                        <label htmlFor="staticEmail">Sold To</label>
                                    </div>
                                    <div className="col-md-9">
                                        <CustomerDropDown></CustomerDropDown>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-3 my-auto">
                                        <label htmlFor="staticEmail">Sold To Name</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input disabled type="text" className="form-control" value={shipToName} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 text-right">
                        {showRelatedBOL && (
                            <React.Fragment>
                                <button className="Button CustomButton-Plain mx-1" onClick={onLoadRelatedBols}>
                                    Related BOL
                                </button>
                                <button className="Button CustomButton-Plain mx-1">Download to Excel</button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
            <div className="Header-ListHeader"></div>
        </div>
    );
};

export default OpenOrdersHeader;
