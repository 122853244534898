import React, { useRef, useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import './OpenOrders.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Error, Loading } from '../_common';
import OpenOrdersHeader from '../Header/OpenOrders';
import { OpenOrdersFilter } from '../Filter/OpenOrdersFilter';
import { useStoreContext } from '../App/StoreProvider';
import {
    GetAllPurchaseOrderItems_getAllPurchaseOrderItems as PurchaseOrderLineItem,
    GetAllPurchaseOrderItems,
    GetPurchaseOrderList_getPurchaseOrderList as PurchaseOrder,
    GetPurchaseOrderList,
} from '../../types/schemaTypes';
import { GET_ALL_PURCHASEORDER_LINEITEMS, GET_ALL_PURCHASEORDER_LIST } from '../../graphql/client/queries';

import Paper from '@mui/material/Paper';
import {
    SortingState,
    IntegratedSorting,
    SelectionState,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material';
import { Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import LineItemDeliveryInformationModal from './LineItemDeliveryInformationModal/LineItemDeliveryInformationModal';
import * as BOLActions from '../../store/actions/bol';
import { useHistory } from 'react-router';

const PREFIX = 'PO';

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
};
const $grey = '#333';
const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha($grey, 0.015),
        },
    },
}));

const TableComponent = props => <StyledTable {...props} className={classes.tableStriped} />;

const columns = [
    { name: 'lineNumber', title: 'Line No' },
    { name: 'delNo', title: 'Del No' },
    { name: 'productNumber', title: 'Product' },
    { name: 'product', title: 'Product Description' },
    { name: 'standardName', title: 'Standard Name' },
    { name: 'scheduledPickup', title: 'Scheduled Pick Date' },
    { name: 'requestedShipDate', title: 'Requested Ship Date' },
    { name: 'expectedShipDate', title: 'Expected Ship Date' },
    { name: 'quantity', title: 'Quantity' },
    { name: 'uom', title: 'UOM' },
    { name: 'equipmentNo', title: 'Equipment No' },
    { name: 'sealNo', title: 'Seal No' },
    { name: 'actualShipDate', title: 'Actual Ship Date' },
    { name: 'status', title: 'Status' },
];

const OpenOrdersDetails = ({ match }: any): JSX.Element => {
    const [activeKey, setActiveKey] = useState('0');
    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    // TODO: Move local state to jobListReducer
    const [state, dispatch] = useStoreContext();
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [selectedPO, setSelectedPO] = useState(undefined);
    const abortController = new AbortController();
    const currentOffSet = 0;
    const [columnOrder, setColumnOrder] = useState([
        'lineNumber',
        'delNo',
        'productNumber',
        'product',
        'standardName',
        'scheduledPickup',
        'requestedShipDate',
        'expectedShipDate',
        'quantity',
        'uom',
        'equipmentNo',
        'sealNo',
        'actualShipDate',
        'status',
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'lineNumber', width: 100 },
        { columnName: 'delNo', width: 100 },
        { columnName: 'productNumber', width: 100 },
        { columnName: 'product', width: 100 },
        { columnName: 'standardName', width: 100 },
        { columnName: 'scheduledPickup', width: 150 },
        { columnName: 'requestedShipDate', width: 150 },
        { columnName: 'expectedShipDate', width: 150 },
        { columnName: 'quantity', width: 100 },
        { columnName: 'uom', width: 100 },
        { columnName: 'equipmentNo', width: 100 },
        { columnName: 'sealNo', width: 100 },
        { columnName: 'actualShipDate', width: 150 },
        { columnName: 'status', width: 100 },
    ]);

    const {
        purchaseOrder: { searchPhrase, fromDate, toDate, product, poNumber, pickupLocation },
        customerList: { customer },
        bol: { relatedBol },
    } = state;
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageSizes] = useState([10, 20, 30, 50]);
    const currentFilters = useRef({});
    const selectedpo = useRef({});
    const [selection, setSelection] = React.useState<Array<number | string>>([]);
    const [sorting, setSorting] = useState([{ columnName: 'ponumber', direction: 'asc' }]);
    const abortRequest = (): void => {
        abortController.abort();
    };
    const totalRowsCount = 0;
    const [orderDetails, setOrderDetails] = useState(null);
    const [showDeliveryInformationModal, setShowDeliveryInformationModal] = useState(false);
    const [lineNo, setLineNo] = useState(0);
    const [orderNo, setOrderNo] = useState(parseInt(match.params.orderNo));

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('PurchaseOrdersContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    const history = useHistory();

    document.title = 'Order Details';

    const { loading: purchaseOrderDetailsLoading, error: purchaseOrderError, data: purchaseOrderDetails } = useQuery(
        GET_ALL_PURCHASEORDER_LIST,
        {
            variables: {
                input: {
                    orderNo: parseInt(match.params.orderNo),
                },
            },
            fetchPolicy: 'network-only',
        },
    );

    let purchaseOrderDetailsByOrderNo: any;
    if (
        purchaseOrderDetails &&
        purchaseOrderDetails.getPurchaseOrderList &&
        purchaseOrderDetails.getPurchaseOrderList.length !== 0
    ) {
        purchaseOrderDetails.getPurchaseOrderList.forEach((element: PurchaseOrder | null): void => {
            if (element !== null) {
                purchaseOrderDetailsByOrderNo = element;
            }
        });
    }

    if (purchaseOrderDetailsByOrderNo && purchaseOrderDetailsByOrderNo !== null) {
        const enteredDate = purchaseOrderDetailsByOrderNo.dateEntered;
        const formattedenteredDate = moment(new Date(enteredDate)).format('yyyy-MM-DD');
        purchaseOrderDetailsByOrderNo.formatEnteredData = formattedenteredDate;
    }

    const { loading: purchaseOrderLineItemsLoading, error, data: purchaseOrderLineItemsData } = useQuery(
        GET_ALL_PURCHASEORDER_LINEITEMS,
        {
            variables: {
                input: {
                    orderNo: parseInt(match.params.orderNo),
                },
            },
            fetchPolicy: 'network-only',
            pollInterval: 120000,
        },
    );

    const purchaseOrderLineItems: PurchaseOrderLineItem[] = [];
    if (
        purchaseOrderLineItemsData &&
        purchaseOrderLineItemsData.getAllPurchaseOrderItems &&
        purchaseOrderLineItemsData.getAllPurchaseOrderItems.length !== 0
    ) {
        purchaseOrderLineItemsData.getAllPurchaseOrderItems.forEach((element: PurchaseOrderLineItem | null): void => {
            if (element !== null) {
                purchaseOrderLineItems.push(element);
            }
        });
    }
    console.log('purchaseOrderLineItemsData', purchaseOrderLineItemsData);
    const Cell = (props: any) => {
        const { column, row, tablerow } = props;
        if (column.name === 'lineNumber') {
            return (
                <td className="rd" colSpan={1} style={{ textAlign: 'left', paddingRight: '8px', paddingLeft: '8px' }}>
                    <span
                        className="po-orderno"
                        onClick={(): void => openDeliveryInformation(parseInt(row.lineNumber))}
                    >
                        {row.lineNumber}
                    </span>
                </td>
            );
        }
        return <Table.Cell {...props} />;
    };

    const openDeliveryInformation = (lineNo: number): void => {
        console.log('orderNo', orderNo, 'lineNo', lineNo);
        setLineNo(lineNo);
        setShowDeliveryInformationModal(true);
    };

    const handleOnHide = (): void => {
        setShowDeliveryInformationModal(false);
    };

    const onLoadRelatedBols = () => {
        if (selectedPO) {
            dispatch(BOLActions.setFilterValue(selectedPO, BOLActions.Actions.FILTER_PONO));
            dispatch(BOLActions.setFilterValue(relatedBol + 1, BOLActions.Actions.BOL_ONCLICK_RELATEDBOL));
            history.push(`/bills_lading`);
        }
    };

    React.useEffect(() => {
        const queryParam = new URLSearchParams(window.location.search);
        const selectedPo: any = queryParam.get('selectedPO');
        if (purchaseOrderDetailsByOrderNo && purchaseOrderDetailsByOrderNo.customerPONumber === selectedPo) {
            setSelectedPO(selectedPo);
        }
    }, [purchaseOrderDetailsByOrderNo]);

    return (
        <div className="PurchaseOrders-Container">
            <OpenOrdersHeader
                showRelatedBOL={selectedPO ? true : false}
                onLoadRelatedBols={onLoadRelatedBols}
                shipToName={purchaseOrderLineItems.length > 0 ? purchaseOrderLineItems[0].soldToName?.toString() : ''}
            />
            <div id="BodyGrid" className="PurchaseOrders-Body row">
                {/* <OpenOrdersFilter abortRequest={abortRequest} onFilterToggle={setListContainerHeight} /> */}
                <div id="GridContainer" className="pl-2 col-md-12 PurchaseOrders-List">
                    <div className="PurchaseOrdersList">
                        <div className="JonItem-Container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row py-2">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Ship To:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderDetailsByOrderNo !== undefined
                                                                ? purchaseOrderDetailsByOrderNo.shipTo
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Ship To Name:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderLineItems.length > 0
                                                                ? purchaseOrderLineItems[0].shipToName?.toString()
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Order No:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderDetailsByOrderNo !== undefined
                                                                ? purchaseOrderDetailsByOrderNo.orderNo
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Sold To:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderLineItems.length > 0
                                                                ? purchaseOrderLineItems[0].soldTo?.toString()
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Sold To Name:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderLineItems.length > 0
                                                                ? purchaseOrderLineItems[0].soldToName?.toString()
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Customer PO No:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderDetailsByOrderNo !== undefined
                                                                ? purchaseOrderDetailsByOrderNo.customerPONumber
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Date Entered:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="date"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderDetailsByOrderNo !== undefined
                                                                ? purchaseOrderDetailsByOrderNo.formatEnteredData
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Sales Person:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderLineItems.length > 0
                                                                ? purchaseOrderLineItems[0].salesPerson?.toString()
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Origination Site:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderDetailsByOrderNo !== undefined
                                                                ? purchaseOrderDetailsByOrderNo.pickUpLocation
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-4 my-auto">Status:</div>
                                                <div className="col-md-8 my-auto">
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={
                                                            purchaseOrderLineItems.length > 0
                                                                ? purchaseOrderLineItems[0].status?.toString()
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-2">Notes:</div>
                                                <div className="col-md-10 my-auto">
                                                    <textarea disabled className="form-control">
                                                        {purchaseOrderLineItems.length > 0
                                                            ? purchaseOrderLineItems[0].notes?.toString()
                                                            : ''}
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card my-3">
                                <div className="card-body">
                                    <Tabs>
                                        <Tab eventKey="orderLines" title="Order Lines">
                                            <Paper>
                                                <Grid rows={purchaseOrderLineItems} columns={columns}>
                                                    {/* <PagingState
                                                        currentPage={currentPage}
                                                        onCurrentPageChange={setCurrentPage}
                                                        pageSize={pageSize}
                                                        onPageSizeChange={setPageSize}
                                                    /> */}
                                                    <SelectionState
                                                        selection={selection}
                                                        onSelectionChange={setSelection}
                                                    />
                                                    {/* <IntegratedPaging /> */}
                                                    {/* <IntegratedSelection /> */}
                                                    <SortingState
                                                        defaultSorting={[{ columnName: 'lineNo', direction: 'asc' }]}
                                                    />
                                                    <IntegratedSorting />
                                                    {/* <DragDropProvider /> */}
                                                    {/* <CustomPaging totalCount={totalRowsCount} /> */}
                                                    {/* <Table tableComponent={TableComponent} /> */}
                                                    <Table tableComponent={TableComponent} cellComponent={Cell} />
                                                    <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                                    <TableHeaderRow showSortingControls />
                                                    {/* <TableColumnReordering
                                                        order={columnOrder}
                                                        onOrderChange={setColumnOrder}
                                                    /> */}
                                                    {/* <TableSelection showSelectAll /> */}
                                                    <TableColumnVisibility />
                                                    {/* <PagingPanel pageSizes={pageSizes} /> */}
                                                </Grid>
                                            </Paper>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LineItemDeliveryInformationModal
                show={showDeliveryInformationModal}
                onClickHide={handleOnHide}
                lineNo={lineNo}
                orderNo={orderNo}
            />
        </div>
    );
};

export default OpenOrdersDetails;
