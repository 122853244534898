import gql from 'graphql-tag';

export default gql`
    query GetPurchaseOrderLineItemDeliveryInformation($input: GetPurchaseOrderLineItemDeliveryInformationInput) {
        getPurchaseOrderLineItemDeliveryInformation(input: $input) {
            customerNo
            customerName
            orderNo
            lineNo
            customerPO
            city
            state
            country
            carrierName
            equipmentType
            deliveryTerms
            incoterms
            shipmentNumber
            loadingPort
            destinationPort
            bookingNumber
            vesselName
            numberContainers
            lastLoadDate
            sailDate
            etaDate
            totalNetWeight
            totalGrossWeight
            voyageNumber
            routingNotes
        }
    }
`;
