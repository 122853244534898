import './SearchBar.css';
import 'material-design-icons/iconfont/material-icons.css';
import React from 'react';
import { useStoreContext } from '../App/StoreProvider';
import * as UserListActions from '../../store/actions/userList';
import * as CustomerListActions from '../../store/actions/customerList';
import * as CustomerParentListActions from '../../store/actions/customerParentList';
import { SearchTypeEnum } from '../../types/SearchTypeEnum';

interface SearchBarProps {
    type?: SearchTypeEnum;
    onChange?: (text: string) => void;
}

const SearchBar = ({ type = SearchTypeEnum.Unknown, onChange }: SearchBarProps): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const search: React.Ref<HTMLInputElement> = React.createRef();

    const updateSearchPhrase = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const searchPhrase = search && search.current ? search.current.value : '';
            if (type == SearchTypeEnum.User) dispatch(UserListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.Customer) dispatch(CustomerListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.CustomerParent)
                dispatch(CustomerParentListActions.setSearchPhrase(searchPhrase));
        }
    };

    let searchValue = '';

    if (type == SearchTypeEnum.User) searchValue = state.userList.searchPhrase;
    if (type == SearchTypeEnum.Customer) searchValue = state.customerList.searchPhrase;
    if (type == SearchTypeEnum.CustomerParent) searchValue = state.customerParentList.searchPhrase;
    return (
        <div className="SearchBar-Container">
            <i className="material-icons SearchBar-Icon">search</i>
            <input
                id="textSearch"
                type="text"
                placeholder="Search By Name, Email & Role"
                onChange={e => onChange && onChange(e.target.value)}
                className="SearchBar-Input"
                ref={search}
                defaultValue=""
                title="Search By Name, Email & Role"
                // value={state.messageList.searchPhrase}
                onKeyDown={(e): void => updateSearchPhrase(e)}
                autoComplete="off"
            />
        </div>
    );
};

export default SearchBar;
