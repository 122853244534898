import gql from 'graphql-tag';

export default gql`
    query GetPurchaseOrderPickupLocation($input: GetAllPurchaseOrderPickupLocationInput) {
        getPurchaseOrderPickupLocation(input: $input) {
            pickUpLocationId
            pickUpLocation
        }
    }
`;
