import { Reducer } from 'react';
import { Actions } from '../../actions/customerList';
import { Action, CustomerListState } from '../../types';

const initialCustomerListState: CustomerListState = {
    searchPhrase: '',
    customer: 0,
    customerList: [],
};

const setSearchPhrase = (search: string, state: CustomerListState): CustomerListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const setCustomerList = (customerList, state: CustomerListState): CustomerListState => {
    return {
        ...state,
        customerList: customerList,
    };
};

const setCustomerId = (customerId, state: CustomerListState): CustomerListState => {
    return {
        ...state,
        customer: customerId,
    };
};

const customerListReducer: Reducer<CustomerListState, Action> = (state, action): CustomerListState => {
    let newState = { ...state };
    console.log('customerListReducer - Tet');
    switch (action.type) {
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_CUSTOMER_LIST:
            newState = setCustomerList(action.payload, state);
            break;
        case Actions.UPDATE_CUSTOMER_ID:
            newState = setCustomerId(action.payload, state);
            break;
    }

    return newState;
};

export { initialCustomerListState, customerListReducer };
