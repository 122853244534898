import React, { FC, useEffect, useRef, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import './OpenStatementAcct.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Error, Loading } from '../_common';
import { OpenStatementAcctFilters } from '../Filter/OpenStatementAcctFilter';
import { useStoreContext } from '../App/StoreProvider';

import Paper from '@mui/material/Paper';
import {
    SortingState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    TableColumnReordering,
    TableSelection,
    PagingPanel,
    TableColumnResizing,
    ColumnChooser,
    Toolbar,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material';
import { GetAllOpenInvoiceList_getAllOpenInvoiceList as Invoice, GetAllOpenInvoiceList } from '../../types/schemaTypes';
import { GET_ALL_OPEN_INVOICE_LIST } from '../../graphql/client/queries';
import OpenStatementAcctHeader from '../Header/OpenStatementAcctHeaders';

const PAGE_SIZE = 50;

const PREFIX = 'Demo';

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
};
const $grey = '#333';
const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha($grey, 0.015),
        },
    },
}));

const TableComponent = props => <StyledTable {...props} className={classes.tableStriped} />;
const OpenStatementAcct: FC = (): JSX.Element => {
    // TODO: Move local state to jobListReducer
    const [state, dispatch] = useStoreContext();
    const {
        invoice: { searchString, addressNumber, invoiceStatus, poNumber, invoiceNumber },
        customerList: { customer },
    } = state;
    const [listHeight, setListHeight] = useState(window.screen.height);
    const abortController = new AbortController();
    let currentOffSet = 0;

    const [columns] = useState([
        { name: 'invoiceNo', title: 'Invoice Number' },
        { name: 'poNumber', title: 'PO Number' },
        { name: 'shipName', title: 'Ship Name' },
        { name: 'shipCity', title: 'Ship City' },
        { name: 'invoiceDate', title: 'Invoice Date' },
        { name: 'netDueDate', title: 'Net Due Date' },
        { name: 'closedDate', title: 'Closed Date' },
        { name: 'pastDueDays', title: 'Past Due Days' },
        { name: 'remarks', title: 'Remarks' },
        { name: 'grossAmount', title: 'Gross Amount' },
        { name: 'openAmount', title: 'Open Amount' },
        { name: 'invoiceType', title: 'Invoice Type' },
        { name: 'taxAmount', title: 'Tax' },
    ]);

    const [columnOrder, setColumnOrder] = useState([
        'invoiceType',
        'invoiceDate',
        'netDueDate',
        'closedDate',
        'pastDueDays',
        'invoiceNo',
        'poNumber',
        'shipName',
        'shipCity',
        'taxAmount',
        'grossAmount',
        'openAmount',
        'remarks',
    ]);
    const [defaultColumnWidths] = useState([
        { columnName: 'invoiceNo', width: 180 },
        { columnName: 'poNumber', width: 180 },
        { columnName: 'shipName', width: 180 },
        { columnName: 'shipCity', width: 180 },
        { columnName: 'invoiceDate', width: 180 },
        { columnName: 'netDueDate', width: 180 },
        { columnName: 'closedDate', width: 180 },
        { columnName: 'pastDueDays', width: 180 },
        { columnName: 'grossAmount', width: 180 },
        { columnName: 'openAmount', width: 180 },
        { columnName: 'invoiceType', width: 180 },
        { columnName: 'taxAmount', width: 180 },
        { columnName: 'remarks', width: 180 },
    ]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageSizes] = useState([10, 20, 30, 50]);
    const currentFilters = useRef({});
    const invoiceData = useRef({});
    const [selection, setSelection] = React.useState<Array<number | string>>([]);
    const [sorting, setSorting] = useState([{ columnName: 'invoiceDate', direction: 'desc' }]);
    const abortRequest = (): void => {
        abortController.abort();
    };

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('InvoicesContainer');
        const listDiv = document.getElementById('InvoicesContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    currentOffSet = currentPage;
    if (
        currentFilters &&
        (currentFilters.current['searchString'] !== searchString ||
            currentFilters.current['customer'] !== customer ||
            currentFilters.current['addressNumber'] !== addressNumber ||
            currentFilters.current['invoiceStatus'] !== invoiceStatus ||
            currentFilters.current['poNumber'] !== poNumber ||
            currentFilters.current['invoiceNumber'] !== invoiceNumber)
    ) {
        currentOffSet = 0;
        setCurrentPage(0);
        setSelection([]);
    }

    document.title = 'Open Statement of Account';
    const variables = {
        input: {
            searchString,
            customer,
            addressNumber,
            invoiceStatus,
            pageSize,
            offSet: currentOffSet,
            sortParam: sorting[0].columnName,
            sortDirection: sorting[0].direction,
            invoiceNumber,
            poNumber,
        },
    };

    currentFilters.current = {
        searchString,
        customer,
        addressNumber,
        invoiceStatus,
        invoiceNumber,
        poNumber,
    };
    console.log('variables', variables, 'currentPage', currentPage, 'pageSize', pageSize);

    const onDownloadOpenInvoice = () => {
        console.log('onDownloadBol - selection', selection);
        if (selection.length > 0) {
            let invoiceNos = '';
            selection.forEach(element => {
                invoiceNos =
                    invoiceNos === ''
                        ? invoiceData.current[element].invoiceNo
                        : invoiceNos + ',' + invoiceData.current[element].invoiceNo;
            });
            console.log('onDownloadBol - invoiceNos', invoiceNos);
            const queryparams = `?invoiceNo=${invoiceNos}`;
            window.open(
                `${process.env.REACT_APP_API_URL}OpenStatementAcct/DownloadOpenInvoice${queryparams}`,
                '_blank',
            );
        }
    };

    const onPrintOpenInvoice = () => {
        console.log('onPrintBol - selection', selection);
        console.log('onDownloadBol - selection', selection);
        if (selection.length > 0) {
            let invoiceNos = '';
            selection.forEach(element => {
                invoiceNos =
                    invoiceNos === ''
                        ? invoiceData.current[element].invoiceNo
                        : invoiceNos + ',' + invoiceData.current[element].invoiceNo;
            });
            console.log('onDownloadBol - invoiceNos', invoiceNos);
            const queryparams = `?invoiceNo=${invoiceNos}`;
            window.open(`${process.env.REACT_APP_API_URL}OpenStatementAcct/PrintOpenInvoice${queryparams}`, '_blank');
        }
    };

    const onPageNumberChanges = count => {
        setSelection([]);
        setCurrentPage(0);
        setPageSize(count);
        console.log('onPageNumberChanges', count);
    };

    const onPageChanges = count => {
        setSelection([]);
        setCurrentPage(count);
        console.log('onPageChanges', count);
    };

    const Cell = (props: any) => {
        const { column, row, tablerow }: any = props;
        if (column.name === 'grossAmount') {
            const value = row.grossAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            return (
                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium TableCell-cell TableCell-cellNoWrap base-genricCss ">
                    ${value}
                </td>
            );
        }
        if (column.name === 'openAmount') {
            const value = row.openAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            return (
                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium TableCell-cell TableCell-cellNoWrap base-genricCss">
                    ${value}
                </td>
            );
        }
        if (column.name === 'taxAmount') {
            const value = row.taxAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            return (
                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium TableCell-cell TableCell-cellNoWrap base-genricCss">
                    ${value}
                </td>
            );
        }
        return <Table.Cell {...props} />;
    };

    if (customer && customer !== 0) {
        return (
            <Query query={GET_ALL_OPEN_INVOICE_LIST} variables={variables} fetchPolicy="network-only">
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetAllOpenInvoiceList | undefined>): JSX.Element | null => {
                    const invoiceList: Invoice[] = [];
                    let totalRowsCount = 0;
                    // if (error) {
                    //     return <Error error={error} />;
                    // } else {

                    if (data && data.getAllOpenInvoiceList && data.getAllOpenInvoiceList.length !== 0) {
                        data.getAllOpenInvoiceList.forEach((element: Invoice | null): void => {
                            if (element !== null) {
                                totalRowsCount = Number(element.totalRecordsCount);
                                invoiceList.push(element);
                            }
                        });
                        invoiceData.current = invoiceList;
                    }
                    // }

                    return (
                        <div className="OpenStatementAcct-Container">
                            {error && <Error error={error} />}
                            <OpenStatementAcctHeader
                                onDownloadOpenInvoices={onDownloadOpenInvoice}
                                onPrintOpenInvoices={onPrintOpenInvoice}
                            />
                            <div id="BodyGrid" className="Invoices-Body row">
                                <OpenStatementAcctFilters
                                    abortRequest={abortRequest}
                                    onFilterToggle={setListContainerHeight}
                                />
                                <div id="GridContainer" className="pl-0 col-md-9 OpenStatementAcct-List">
                                    <div className="OpenStatementAcctList">
                                        <div className="JonItem-Container">
                                            {loading && (
                                                <div className="SpinnerContainer">
                                                    <Loading />
                                                </div>
                                            )}
                                            <Paper>
                                                <Grid rows={invoiceList} columns={columns}>
                                                    <SelectionState
                                                        selection={selection}
                                                        onSelectionChange={setSelection}
                                                    />
                                                    <IntegratedSelection />
                                                    <DragDropProvider />
                                                    <SortingState onSortingChange={setSorting} />
                                                    <PagingState
                                                        currentPage={currentPage}
                                                        onCurrentPageChange={onPageChanges}
                                                        pageSize={pageSize}
                                                        onPageSizeChange={onPageNumberChanges}
                                                    />
                                                    <CustomPaging totalCount={totalRowsCount} />
                                                    <Table tableComponent={TableComponent} cellComponent={Cell} />
                                                    <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                                    <TableHeaderRow showSortingControls />
                                                    <TableColumnReordering
                                                        order={columnOrder}
                                                        onOrderChange={setColumnOrder}
                                                    />
                                                    <TableSelection showSelectAll />
                                                    <TableColumnVisibility />
                                                    <PagingPanel pageSizes={pageSizes} />
                                                </Grid>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    } else {
        return (
            <div className="OpenStatementAcct-Container">
                <OpenStatementAcctHeader
                    onDownloadOpenInvoices={onDownloadOpenInvoice}
                    onPrintOpenInvoices={onPrintOpenInvoice}
                />
                <div id="BodyGrid" className="OpenStatementAcct-Body row">
                    <OpenStatementAcctFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                    <div id="GridContainer" className="pl-0 col-md-9 OpenStatementAcct-List">
                        <div className="OpenStatementAcctList">
                            <div className="JonItem-Container">
                                <Paper>
                                    <Grid rows={[]} columns={columns}>
                                        <PagingState
                                            currentPage={currentPage}
                                            onCurrentPageChange={setCurrentPage}
                                            pageSize={pageSize}
                                            onPageSizeChange={setPageSize}
                                        />
                                        <SelectionState selection={selection} onSelectionChange={setSelection} />
                                        <IntegratedSelection />
                                        <SortingState defaultSorting={[{ columnName: 'ponumber', direction: 'asc' }]} />
                                        <DragDropProvider />
                                        <CustomPaging totalCount={0} />
                                        <Table tableComponent={TableComponent} />
                                        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                        <TableHeaderRow showSortingControls />
                                        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
                                        <TableSelection showSelectAll />
                                        <TableColumnVisibility />
                                        <PagingPanel pageSizes={pageSizes} />
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default OpenStatementAcct;
