import { useQuery } from '@apollo/react-hooks';
import 'material-design-icons/iconfont/material-icons.css';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { GET_PURCHASEORDER_LINEITEM_DELIVERYINFORMATION } from '../../../graphql/client/queries';
import './LineItemDeliveryInformationModal.scss';

interface LineItemDeliveryInformationModalProps {
    orderNo: number;
    lineNo: number;
    onClickHide: () => void;
    show: boolean;
}

const LineItemDeliveryInformationModal = ({
    orderNo,
    lineNo,
    onClickHide,
    show,
}: LineItemDeliveryInformationModalProps): JSX.Element => {
    const variables = {
        orderNo,
        lineNo,
    };

    const { data: lineItemDeliveryInformationData, loading: userAuditLoading, error: userAuditError } = useQuery(
        GET_PURCHASEORDER_LINEITEM_DELIVERYINFORMATION,
        {
            variables: {
                input: {
                    orderNo,
                    lineNo,
                },
            },
            fetchPolicy: 'network-only',
        },
    );

    console.log('lineItemDeliveryInformationData', lineItemDeliveryInformationData);

    let lineItemDeliveryInformation: any;
    if (
        lineItemDeliveryInformationData &&
        lineItemDeliveryInformationData.getPurchaseOrderLineItemDeliveryInformation
    ) {
        lineItemDeliveryInformation = lineItemDeliveryInformationData.getPurchaseOrderLineItemDeliveryInformation;
    }

    return (
        <Modal show={show} onHide={onClickHide} size="xl">
            <Modal.Header className="AddEditUserModal-Header">
                <Modal.Title className="AddEditUserModal-Header-Container">
                    {/* <b>Line #{lineNo}  </b> */}
                    <div className="AddEditUserModal-Title">
                        {' '}
                        Delivery Information
                        <button
                            className="material-icons AddEditUserModal-X-Button"
                            onClick={onClickHide}
                            style={{ position: 'absolute', right: '20px' }}
                        >
                            close
                        </button>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card">
                    <div className="card-body">
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">City / State:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={
                                                lineItemDeliveryInformation?.city +
                                                ', ' +
                                                lineItemDeliveryInformation?.state
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Country:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.country}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Carrier Name:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.carrierName}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Equipment Type:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.equipmentType}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Delivery Terms:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.deliveryTerms}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Incoterms:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.incoterms}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Loading Port:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.loadingPort}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Destination Port:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.destinationPort}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Booking No:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.bookingNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Vessel Name:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.vesselName}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Voyage No:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.voyageNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">No of Containers:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.numberContainers}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Last Load Date:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={lineItemDeliveryInformation?.lastLoadDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Sail Date:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={lineItemDeliveryInformation?.sailDate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">ETA Date:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={lineItemDeliveryInformation?.etaDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Total Net Weight:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.totalNetWeight}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Total Gross Weight:</div>
                                    <div className="col-md-8 my-auto">
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={lineItemDeliveryInformation?.totalGrossWeight}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4 my-auto">Routing Notes:</div>
                                    <div className="col-md-8 my-auto">
                                        <textarea className="form-control" disabled>
                                            {lineItemDeliveryInformation?.routingNotes}
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LineItemDeliveryInformationModal;
