import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import SearchBar from '../../SearchBar/SearchBar';
import './ReportsHeader.scss';
import { SearchTypeEnum } from '../../../types/SearchTypeEnum';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';
import CustomerDropDown from '../CustomerDropDown';
import { useStoreContext } from '../../App/StoreProvider';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

const ReportsHeader = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        report: { searchString, fromDate, toDate, product, pickupLocation, poNumber },
        customerList: { customer },
    } = state;
    const variables = {
        input: '',
    };

    const onDownloadExcel = (e): void => {
        // console.log('onDownloadExcel');
        // console.log('ReportsHeader - customer', customer, 'process.env.REACT_APP_API_URL,', process.env.REACT_APP_API_URL, 'process.env.REACT_APP_AUTHORITY_URL', process.env.REACT_APP_AUTHORITY_URL);
        const queryparams = `?Customer=${customer}&SearchString=${searchString}&FromDate=${
            fromDate ? fromDate : ''
        }&Todate=${
            toDate ? toDate : ''
        }&PageSize=0&Product=${product}&PickupLocation=${pickupLocation}&PONumber=${poNumber}`;
        window.open(`${process.env.REACT_APP_API_URL}Reports/ExportReport${queryparams}`, '_blank');
    };

    return (
        <div className="R-Header-Container">
            <div className="R-Header-SearchBar">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-3 my-auto">
                                <label htmlFor="staticEmail">Customer</label>
                            </div>
                            <div className="col-md-6">
                                <CustomerDropDown></CustomerDropDown>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-5 text-right">
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadExcel}>
                            Download to Excel
                        </button>
                    </div>
                </div>
            </div>
            <div className="Header-ListHeader"></div>
        </div>
    );
};

export default ReportsHeader;
