import React, { FC, useEffect, useRef, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import './PurchaseOrders.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Error, Loading } from '../_common';
import PurchaseOrdersHeader from '../Header/PurchaseOrdersHeader';
import { Routes } from '../../utils';
import { PurchaseOrderFilters } from '../Filter/PurchaseOrdersFilter';
import { useStoreContext } from '../App/StoreProvider';
import * as BOLActions from '../../store/actions/bol';
import { useHistory, withRouter } from 'react-router';

import Paper from '@mui/material/Paper';
import {
    SortingState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    TableColumnReordering,
    TableSelection,
    PagingPanel,
    TableColumnResizing,
    ColumnChooser,
    Toolbar,
    TableColumnVisibility,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material';

import {
    GetPurchaseOrderList_getPurchaseOrderList as PurchaseOrder,
    GetPurchaseOrderList,
} from '../../types/schemaTypes';
import { GET_ALL_PURCHASEORDER_LIST } from '../../graphql/client/queries';
import { Route } from 'react-router-dom';
// const [state] = useStoreContext();

const PREFIX = 'PO';

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
};
const $grey = '#333';
const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha($grey, 0.015),
        },
    },
}));

const TableComponent = props => <StyledTable {...props} className={classes.tableStriped} />;

const columns = [
    { name: 'selection', title: ' ' },
    { name: 'orderNo', title: 'Order No' },
    { name: 'customerPONumber', title: 'Customer PO' },
    { name: 'customerPODate', title: 'Customer PO Date' },
    { name: 'dateEntered', title: 'Date Entered' },
    { name: 'pickUpLocation', title: 'Pickup Location' },
    { name: 'shipTo', title: 'Ship To' },
    { name: 'shipToName', title: 'Ship To Name' },
    { name: 'productName', title: 'Product' },
    { name: 'uom', title: 'UoM' },
    { name: 'quantityonPO', title: 'Quantity on PO' },
    { name: 'quantityShipped', title: 'Quantity Shipped' },
    { name: 'quantityRemaining', title: 'Quantity Remaining' },
];

const PurchaseOrders = (): JSX.Element => {
    const [activeKey, setActiveKey] = useState('0');

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    // TODO: Move local state to jobListReducer
    const [state, dispatch] = useStoreContext();
    const {
        purchaseOrder: { searchPhrase, fromDate, toDate, product, poNumber, pickupLocation, orderStatus, shipTo },
        customerList: { customer },
        bol: { relatedBol },
    } = state;
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [selectedPO, setSelectedPO] = useState(undefined);
    const abortController = new AbortController();
    let currentOffSet = 0;

    console.log('customerList: { customer }', customer);

    const [columnOrder, setColumnOrder] = useState([
        'selection',
        'orderNo',
        'customerPONumber',
        'customerPODate',
        'dateEntered',
        'pickUpLocation',
        'shipTo',
        'shipToName',
        'productName',
        'uom',
        'quantityonPO',
        'quantityShipped',
        'quantityRemaining',
    ]);
    const [defaultColumnWidths] = useState([
        { columnName: 'selection', width: 50 },
        { columnName: 'orderNo', width: 180 },
        { columnName: 'customerPONumber', width: 180 },
        { columnName: 'customerPODate', width: 180 },
        { columnName: 'dateEntered', width: 240 },
        { columnName: 'pickUpLocation', width: 180 },
        { columnName: 'shipTo', width: 180 },
        { columnName: 'shipToName', width: 180 },
        { columnName: 'productName', width: 180 },
        { columnName: 'uom', width: 180 },
        { columnName: 'quantityonPO', width: 180 },
        { columnName: 'quantityShipped', width: 180 },
        { columnName: 'quantityRemaining', width: 180 },
    ]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageSizes] = useState([10, 20, 30, 50]);
    const currentFilters = useRef({});
    const selectedpo = useRef({});
    const [selection, setSelection] = React.useState<Array<number | string>>([]);
    const [sorting, setSorting] = useState([{ columnName: 'orderNo', direction: 'asc' }]);
    const abortRequest = (): void => {
        abortController.abort();
    };
    let totalRowsCount = 0;
    // const [selectedpo, setselectedPo] = useState('');

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('PurchaseOrdersContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    document.title = 'Purchase Orders';
    console.log('currentFilters', currentFilters);

    console.log('state - purchaseOrder', searchPhrase);
    currentOffSet = currentPage;
    if (
        currentFilters &&
        (currentFilters.current['searchPhrase'] !== searchPhrase ||
            currentFilters.current['fromDate'] !== fromDate ||
            currentFilters.current['toDate'] !== toDate ||
            currentFilters.current['customer'] !== customer ||
            currentFilters.current['product'] !== product ||
            currentFilters.current['poNumber'] !== poNumber ||
            currentFilters.current['pickupLocation'] !== pickupLocation ||
            currentFilters.current['orderStatus'] !== orderStatus ||
            currentFilters.current['shipTo'] !== shipTo)
    ) {
        currentOffSet = 0;
        setCurrentPage(0);
        setSelection([]);
    }

    const variables = {
        input: {
            searchString: searchPhrase,
            customer,
            fromDate,
            toDate,
            pageSize,
            offSet: currentOffSet,
            sortParam: sorting[0].columnName,
            sortDirection: sorting[0].direction,
            product,
            poNumber,
            pickupLocation,
            status: orderStatus,
            shipTo,
        },
    };

    console.log('variables', variables);

    const Cell = (props: any) => {
        const { column, row, tablerow }: any = props;
        if (column.name === 'selection' && column.title === ' ') {
            return (
                <td className="rd" colSpan={1}>
                    <input
                        type="radio"
                        name={'poselection'}
                        radioGroup="poselection"
                        style={{ height: '20px', width: '20px', margin: '0 15' }}
                        onChange={event => onRadioChange(event, row)}
                    ></input>
                </td>
            );
        }
        if (column.name === 'orderNo') {
            return (
                <td className="rd" colSpan={1} style={{ textAlign: 'left', paddingRight: '8px', paddingLeft: '8px' }}>
                    <span className="po-orderno" onClick={(): void => openOrderDetails(row.orderNo)}>
                        {row.orderNo}
                    </span>
                </td>
            );
        }
        return <Table.Cell {...props} />;
    };

    const openOrderDetails = (orderNo: string): void => {
        const URL = `${window.location.origin}${Routes.ORDERS_DETAILS.route}`;
        let newTab;
        if (selectedpo.current['po']) {
            newTab = window.open(`${URL}${orderNo}?selectedPO=${selectedpo.current['po']}`, '_blank');
        } else {
            newTab = window.open(`${URL}${orderNo}`, '_blank');
        }
        setTimeout(function(): void {
            if (newTab) {
                newTab.document.title = orderNo;
            }
        }, 100);
    };

    const history = useHistory();
    const onRadioChange = (e, row) => {
        console.log('onRadioChange', e, row);
        selectedpo.current = { po: row.customerPONumber };
        // setSelectedPO(row.poNumber);
        // setselectedPo(row.poNumber);
        // dispatch(BOLActions.setFilterValue(row.poNumber, BOLActions.Actions.FILTER_PONO));
        // history.push(`/bills_lading`);
    };

    const onLoadRelatedBols = () => {
        console.log('relatedBol', relatedBol);
        console.log('onLoadRelatedBols - selectedpo', selectedpo);
        if (selectedpo.current['po']) {
            dispatch(BOLActions.setFilterValue(selectedpo.current['po'], BOLActions.Actions.FILTER_PONO));
            dispatch(BOLActions.setFilterValue(relatedBol + 1, BOLActions.Actions.BOL_ONCLICK_RELATEDBOL));
            history.push(`/bills_lading`);
        }
    };

    const onPageNumberChanges = count => {
        setSelection([]);
        setCurrentPage(0);
        setPageSize(count);
        console.log('onPageNumberChanges', count);
    };

    const onPageChanges = count => {
        setSelection([]);
        setCurrentPage(count);
        console.log('onPageChanges', count);
    };

    currentFilters.current = {
        searchPhrase,
        customer,
        fromDate,
        toDate,
        product,
        poNumber,
        pickupLocation,
        orderStatus,
        shipTo,
    };
    console.log('variables', variables, 'currentPage', currentPage, 'pageSize', pageSize);
    if (customer && customer !== 0) {
        return (
            <Query query={GET_ALL_PURCHASEORDER_LIST} variables={variables} fetchPolicy="network-only">
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetPurchaseOrderList | undefined>): JSX.Element | null => {
                    const purchaseOrderList: PurchaseOrder[] = [];

                    // if (error) {

                    // }

                    if (data && data.getPurchaseOrderList && data.getPurchaseOrderList.length !== 0) {
                        data.getPurchaseOrderList.forEach((element: PurchaseOrder | null): void => {
                            if (element !== null) {
                                totalRowsCount = Number(element.totalRecordsCount);
                                purchaseOrderList.push(element);
                            }
                        });
                        console.log('purchaseOrderList', purchaseOrderList);
                    }

                    return (
                        <div className="PurchaseOrders-Container">
                            {error && <Error error={error} />}
                            <PurchaseOrdersHeader onLoadRelatedBols={onLoadRelatedBols} />
                            <div id="BodyGrid" className="PurchaseOrders-Body row">
                                <PurchaseOrderFilters
                                    abortRequest={abortRequest}
                                    onFilterToggle={setListContainerHeight}
                                />
                                <div id="GridContainer" className="pl-0 col-md-9 PurchaseOrders-List">
                                    <div className="PurchaseOrdersList">
                                        <div className="JonItem-Container">
                                            {loading && (
                                                <div className="SpinnerContainer">
                                                    <Loading />
                                                </div>
                                            )}
                                            <Paper>
                                                <Grid rows={purchaseOrderList} columns={columns}>
                                                    <SelectionState
                                                        selection={selection}
                                                        onSelectionChange={setSelection}
                                                    />
                                                    {/* <IntegratedSelection /> */}
                                                    {/* <SortingState defaultSorting={[{ columnName: 'ponumber', direction: 'asc' }]} /> */}
                                                    <DragDropProvider />
                                                    <SortingState onSortingChange={setSorting} />
                                                    <PagingState
                                                        currentPage={currentPage}
                                                        onCurrentPageChange={onPageChanges}
                                                        pageSize={pageSize}
                                                        onPageSizeChange={onPageNumberChanges}
                                                    />
                                                    <CustomPaging totalCount={totalRowsCount} />
                                                    <Table tableComponent={TableComponent} cellComponent={Cell} />
                                                    <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                                    <TableHeaderRow showSortingControls />
                                                    <TableColumnReordering
                                                        order={columnOrder}
                                                        onOrderChange={setColumnOrder}
                                                    />
                                                    {/* <TableSelection showSelectAll /> */}
                                                    <TableColumnVisibility />
                                                    <PagingPanel pageSizes={pageSizes} />
                                                </Grid>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    } else {
        return (
            <div className="PurchaseOrders-Container">
                <PurchaseOrdersHeader onLoadRelatedBols={onLoadRelatedBols} />
                <div id="BodyGrid" className="PurchaseOrders-Body row">
                    <PurchaseOrderFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                    <div id="GridContainer" className="pl-0 col-md-9 PurchaseOrders-List">
                        <div className="PurchaseOrdersList">
                            <div className="JonItem-Container">
                                <Paper>
                                    <Grid rows={[]} columns={columns}>
                                        <PagingState
                                            currentPage={currentPage}
                                            onCurrentPageChange={setCurrentPage}
                                            pageSize={pageSize}
                                            onPageSizeChange={setPageSize}
                                        />
                                        <SelectionState selection={selection} onSelectionChange={setSelection} />
                                        {/* <IntegratedPaging /> */}
                                        {/* <IntegratedSelection /> */}
                                        <SortingState defaultSorting={[{ columnName: 'ponumber', direction: 'asc' }]} />
                                        <IntegratedSorting />
                                        <DragDropProvider />
                                        <CustomPaging totalCount={totalRowsCount} />
                                        <Table tableComponent={TableComponent} />
                                        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                        <TableHeaderRow showSortingControls />
                                        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
                                        {/* <TableSelection showSelectAll /> */}
                                        <TableColumnVisibility />
                                        <PagingPanel pageSizes={pageSizes} />
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default PurchaseOrders;
