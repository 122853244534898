import gql from 'graphql-tag';

export default gql`
    query GetPurchaseOrderFilterStatus($input: GetAllPurchaseOrderFilterStatusInput) {
        getPurchaseOrderFilterStatus(input: $input) {
            statusId
            status
        }
    }
`;
