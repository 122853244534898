import React, { FC, useEffect, useRef, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import './OpenOrders.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Error, Loading } from '../_common';
import OpenOrdersHeader from '../Header/OpenOrders';
import { Routes } from '../../utils';
import { OpenOrdersFilter } from '../Filter/OpenOrdersFilter';
import { useStoreContext } from '../App/StoreProvider';
import * as BOLActions from '../../store/actions/bol';
import { useHistory, withRouter } from 'react-router';

import Paper from '@mui/material/Paper';
import {
    SortingState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    TableColumnReordering,
    TableSelection,
    PagingPanel,
    TableColumnResizing,
    ColumnChooser,
    Toolbar,
    TableColumnVisibility,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material';

const PREFIX = 'PO';

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
};
const $grey = '#333';
const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha($grey, 0.015),
        },
    },
}));

const TableComponent = props => <StyledTable {...props} className={classes.tableStriped} />;

const columns = [
    { name: 'orderNo', title: 'Order No' },
    { name: 'customPoNo', title: 'Customer PO No' },
    { name: 'shipTo', title: 'Ship To' },
    { name: 'ShipToName', title: 'Ship To Name' },
    { name: 'originationSite', title: 'Origination Site' },
    { name: 'dateEntered', title: 'Date Entered' },
    { name: 'requestShipDate', title: 'Request Ship Date' },
    { name: 'expectedDateShip', title: 'Expected Date Ship' },
    { name: 'status', title: 'Status' },
];

const OpenOrders = (): JSX.Element => {
    const [activeKey, setActiveKey] = useState('0');
    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };
    // TODO: Move local state to jobListReducer
    const [state, dispatch] = useStoreContext();
    const {
        purchaseOrder: { searchPhrase, fromDate, toDate, product, poNumber, pickupLocation },
        customerList: { customer },
        bol: { relatedBol },
    } = state;
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [selectedPO, setSelectedPO] = useState(undefined);
    const abortController = new AbortController();
    let currentOffSet = 0;
    const [columnOrder, setColumnOrder] = useState([
        'orderNo',
        'customPoNo',
        'shipTo',
        'ShipToName',
        'originationSite',
        'dateEntered',
        'requestShipDate',
        'expectedDateShip',
        'status',
    ]);
    const [defaultColumnWidths] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageSizes] = useState([10, 20, 30, 50]);
    const currentFilters = useRef({});
    const selectedpo = useRef({});
    const [selection, setSelection] = React.useState<Array<number | string>>([]);
    const [sorting, setSorting] = useState([{ columnName: 'ponumber', direction: 'asc' }]);
    const abortRequest = (): void => {
        abortController.abort();
    };
    const totalRowsCount = 0;
    // const [selectedpo, setselectedPo] = useState('');

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('PurchaseOrdersContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    document.title = 'Open Orders';

    currentOffSet = currentPage;
    if (
        currentFilters &&
        (currentFilters.current['searchPhrase'] !== searchPhrase ||
            currentFilters.current['fromDate'] !== fromDate ||
            currentFilters.current['toDate'] !== toDate ||
            currentFilters.current['customer'] !== customer ||
            currentFilters.current['product'] !== product ||
            currentFilters.current['poNumber'] !== poNumber ||
            currentFilters.current['pickupLocation'] !== pickupLocation)
    ) {
        currentOffSet = 0;
        setCurrentPage(0);
        setSelection([]);
    }

    const variables = {
        input: {
            searchString: searchPhrase,
            customer,
            fromDate,
            toDate,
            pageSize,
            offSet: currentOffSet,
            sortParam: sorting[0].columnName,
            sortDirection: sorting[0].direction,
            product,
            poNumber,
            pickupLocation,
        },
    };

    const Cell = (props: any) => {
        const { column, row, tablerow } = props;
        // console.log('Cell - column', column, props);
        if (column.name === 'selection' && column.title === ' ') {
            return (
                <td className="rd" colSpan={1}>
                    <input
                        type="radio"
                        name={'poselection'}
                        radioGroup="poselection"
                        style={{ height: '20px', width: '20px', margin: '0 15' }}
                        onChange={event => onRadioChange(event, row)}
                    ></input>
                </td>
            );
            // return <ProgressBarCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    const history = useHistory();
    const onRadioChange = (e, row) => {
        console.log('onRadioChange', e, row);
        selectedpo.current = { po: row.poNumber };
        // setSelectedPO(row.poNumber);
        // setselectedPo(row.poNumber);
        // dispatch(BOLActions.setFilterValue(row.poNumber, BOLActions.Actions.FILTER_PONO));
        // history.push(`/bills_lading`);
    };

    const onLoadRelatedBols = () => {
        console.log('onLoadRelatedBols - selectedpo', selectedpo);
        if (selectedpo.current['po']) {
            dispatch(BOLActions.setFilterValue(selectedpo.current['po'], BOLActions.Actions.FILTER_PONO));
            dispatch(BOLActions.setFilterValue(relatedBol + 1, BOLActions.Actions.BOL_ONCLICK_RELATEDBOL));
            history.push(`/bills_lading`);
        }
    };

    const onPageNumberChanges = count => {
        setSelection([]);
        setCurrentPage(0);
        setPageSize(count);
        console.log('onPageNumberChanges', count);
    };

    const onPageChanges = count => {
        setSelection([]);
        setCurrentPage(count);
        console.log('onPageChanges', count);
    };

    currentFilters.current = { searchPhrase, customer, fromDate, toDate, product, poNumber, pickupLocation };
    return (
        <div className="PurchaseOrders-Container">
            <OpenOrdersHeader showRelatedBOL={false} onLoadRelatedBols={onLoadRelatedBols} shipToName="" />
            <div id="BodyGrid" className="PurchaseOrders-Body row">
                <OpenOrdersFilter abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                <div id="GridContainer" className="pl-0 col-md-9 PurchaseOrders-List">
                    <div className="PurchaseOrdersList">
                        <div className="JonItem-Container">
                            <Paper>
                                <Grid rows={[]} columns={columns}>
                                    <PagingState
                                        currentPage={currentPage}
                                        onCurrentPageChange={setCurrentPage}
                                        pageSize={pageSize}
                                        onPageSizeChange={setPageSize}
                                    />
                                    <SelectionState selection={selection} onSelectionChange={setSelection} />
                                    {/* <IntegratedPaging /> */}
                                    {/* <IntegratedSelection /> */}
                                    <SortingState defaultSorting={[{ columnName: 'dateEntered', direction: 'asc' }]} />
                                    <IntegratedSorting />
                                    <DragDropProvider />
                                    <CustomPaging totalCount={totalRowsCount} />
                                    <Table tableComponent={TableComponent} />
                                    <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                    <TableHeaderRow showSortingControls />
                                    <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
                                    {/* <TableSelection showSelectAll /> */}
                                    <TableColumnVisibility />
                                    <PagingPanel pageSizes={pageSizes} />
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpenOrders;
