import gql from 'graphql-tag';

export default gql`
    query GetCustomersContact($input: CustomerContactInput) {
        getCustomersContact(input: $input) {
            address
            city
            state
            postalCode
            country
            county
            electronicDetails {
                emailType
                emailAddress
            }
            paymentTerms
            contactDetails {
                creditAnalystName
                creditAnalystEmail
                creditAnalystPhoneNumber
                salesRepName
                salesRepEmail
                salesRepPhoneNumber
                custServiceName
                custServiceEmail
                custServicePhoneNumber
            }
        }
    }
`;
