import React from 'react';
import './Error.css';
import { useHistory } from 'react-router';
import { Alert } from 'react-bootstrap';
import toaster from 'toasted-notes';

interface ErrorProps {
    error: {
        message: string;
    };
}

export const Error = (props: ErrorProps): JSX.Element => {
    const { message } = props.error;
    const toasterOptions = { duration: 2500 };

    const history = useHistory();
    const navigateToMaintainence = () => {
        history.push(`/maintenance`);
    };

    const renderToast = (message, type = 'info') => {
        interface ToastProps {
            onClose?: () => void;
        }
        const Comp = (props: ToastProps) => {
            return (
                <Alert variant={type} onClose={props.onClose}>
                    {message}
                </Alert>
            );
        };

        Comp.displayName = 'toast';
        return Comp;
    };

    // navigateToMaintainence();

    if (message.indexOf('connect ECONNREFUSED') > -1) {
        navigateToMaintainence();
    } else {
        toaster.notify(
            renderToast('There is an error while loading the data, please try again', 'danger'),
            toasterOptions,
        );
    }
    return <div></div>;
};
