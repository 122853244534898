export enum SearchTypeEnum {
    Unknown = 0,
    Job = 1,
    Load = 2,
    User = 3,
    LoadManagement = 4,
    Customer = 5,
    Lofa = 6,
    Crew = 7,
    Carrier = 8,
    MessagePreview = 9,
    MessageConversation = 10,
    MessageDriverConversation = 11,
    CustomerParent = 12,
}
