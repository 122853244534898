import React from 'react';
import './Dashboard.scss';
import DashboardHeader from './DashboardHeader';
import DashboardDetails from './DashboardDetails';
import DashboardOpenInvoices from './DashboardOpenInvoices';
import DashboardPurchaseOrders from './DashboardPurchaseOrders';
import DashboardBOLs from './DashboardBOLs';
import DashboardMyInvoices from './DashboardMyInvoices';
import DashboardLinks from './DashboardLinks';

const Dashboard = (): JSX.Element => {
    return (
        <div className="Dashboard-Container">
            <DashboardHeader />
            <div className="row">
                <DashboardDetails />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <DashboardOpenInvoices />
            </div>
            <br></br>
            <div className="row">
                <DashboardPurchaseOrders />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <DashboardBOLs />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <DashboardMyInvoices />
            </div>
            <br></br>
            <div className="row">
                <div className="col">
                    <DashboardLinks />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
