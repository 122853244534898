import { Action } from '../../types';

export enum Actions {
    UPDATE_SEARCH_PHRASE = 'USER_LIST_UPDATE_SEARCH_PHRASE',
    UPDATE_CUSTOMER_SELECTION = 'USER_LIST_UPDATE_CUSTOMER_SELECTION',
}

export const setSearchPhrase = (searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};

export const setCustomerSelection = (customerId): Action => {
    return {
        type: Actions.UPDATE_CUSTOMER_SELECTION,
        payload: customerId,
        error: null,
    };
};
