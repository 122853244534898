import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';

import { GET_ALL_CUSTOMERSLIST } from '../../graphql/client/queries';
import { useQuery } from 'react-apollo';
import { Error } from '../_common';
import { useStoreContext } from '../App/StoreProvider';
import * as CustomerListActions from '../../store/actions/customerList';
import './DashboardHeader.scss';

const DashboardHeader = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const authContext = useAuthContext();
    const {
        auth: { user },
        customerList: { customerList, customer },
    } = state;
    const customerkey = customer;

    const opsCustomer = authContext.userHasPermission(PermissionEnum.Customer);
    const admin = authContext.userHasPermission(PermissionEnum.Admin);
    const customerService = authContext.userHasPermission(PermissionEnum.CustomerService);
    let selectedCustomer = 0;
    const userProfile = user.profile;
    if (opsCustomer && customer === 0 && userProfile && userProfile.role === 'Customer') {
        const userProfile = user.profile;
        const customerId = userProfile.Customer;
        selectedCustomer = Number(customerId);
        dispatch(CustomerListActions.setCustomerID(selectedCustomer));
    }
    const onSelectCustomer = (e): any => {
        dispatch(CustomerListActions.setCustomerID(Number(e.target.value)));
    };

    const input = {
        search: '',
        customerId: opsCustomer ? selectedCustomer : 0,
    };

    const { data, error } = useQuery(GET_ALL_CUSTOMERSLIST, {
        variables: { input },
        skip:
            customerList.length > 0 ||
            (opsCustomer && customer === 0 && userProfile && userProfile.role === 'Customer'),
    });

    if (customerList.length === 0 && data && data.getAllCustomerList && data.getAllCustomerList.length !== 0) {
        const customerusersData = data.getAllCustomerList;
        dispatch(CustomerListActions.setCustomerList(customerusersData));
    }

    if (error) {
        return (
            <div className="Dashboard-Header-Container">
                <div className="Dashboard-Header-SearchBar">
                    <div className="row">
                        {opsCustomer && userProfile && userProfile.role === 'Customer' && (
                            <div>
                                <Error error={error} />
                                {customerList.map(
                                    (customer: any) =>
                                        customerkey == customer.id?.toString() && (
                                            <label key={customerkey.toString()}>
                                                &nbsp;&nbsp;&nbsp;&nbsp; Welcome back, {customer.customerName}
                                            </label>
                                        ),
                                )}
                            </div>
                        )}
                        {(admin || customerService) && (
                            <div className="Dropdown-Class">
                                <select className="form-control" onChange={e => onSelectCustomer(e)} value={customer}>
                                    <option value={0}>-- Select Customer --</option>
                                    {customerList.map((customer: any, index) => (
                                        <option key={customer.id?.toString()} value={customer.id?.toString()}>
                                            {customer.customerName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                </div>
                <div className="Header-ListHeader"></div>
            </div>
        );
    } else {
        if (data && data.getAllCustomerList && data.getAllCustomerList.length !== 0) {
            return (
                <div className="Dashboard-Header-Container">
                    <div className="Dashboard-Header-SearchBar">
                        <div className="row">
                            {opsCustomer && userProfile && userProfile.role === 'Customer' && (
                                <div>
                                    {data.getAllCustomerList.map(
                                        (customer: any) =>
                                            customerkey == customer.id?.toString() && (
                                                <label key={customerkey.toString()}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp; Welcome back, {customer.customerName}
                                                </label>
                                            ),
                                    )}
                                </div>
                            )}
                            {(admin || customerService) && (
                                <div className="Dropdown-Class">
                                    <select
                                        className="form-control"
                                        onChange={e => onSelectCustomer(e)}
                                        value={customer}
                                    >
                                        <option value={0}>-- Select Customer --</option>
                                        {data.getAllCustomerList.map((customer, index) => (
                                            <option key={customer.id?.toString()} value={customer.id?.toString()}>
                                                {customer.customerName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="Header-ListHeader"></div>
                </div>
            );
        } else {
            return (
                <div className="Dashboard-Header-Container">
                    <div className="Dashboard-Header-SearchBar">
                        <div className="row">
                            {opsCustomer && userProfile && userProfile.role === 'Customer' && (
                                <div>
                                    {customerList.map(
                                        (customer: any) =>
                                            customerkey == customer.id?.toString() && (
                                                <div>
                                                    <label key={customerkey.toString()}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp; Welcome back, {customer.customerName}
                                                    </label>
                                                </div>
                                            ),
                                    )}
                                </div>
                            )}
                            {(admin || customerService) && (
                                <div className="Dropdown-Class">
                                    <select
                                        className="form-control"
                                        onChange={e => onSelectCustomer(e)}
                                        value={customer}
                                    >
                                        <option value={0}>-- Select Customer --</option>
                                        {customerList.map((customer: any, index) => (
                                            <option key={customer.id?.toString()} value={customer.id?.toString()}>
                                                {customer.customerName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default DashboardHeader;
