import './Filter.scss';

import React, { useEffect, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import Button from 'react-bootstrap/Button';
import { Error, Loading } from '../_common';
import { useStoreContext } from '../App/StoreProvider';
import * as PurchaseOrderActions from '../../store/actions/purchaseOrder';

interface JobFiltersProps {
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const OpenOrdersFilter = ({ abortRequest, onFilterToggle }: JobFiltersProps): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        purchaseOrder: { searchPhrase, fromDate, toDate, pickupLocation, poNumber, product },
        customerList: { customer },
    } = state;
    const search: React.Ref<HTMLInputElement> = React.createRef();
    const searchPO: React.Ref<HTMLInputElement> = React.createRef();

    const [filter_searchPhrase, setSearchPhrase] = useState(searchPhrase);
    const [filter_pickupLocation, setPickupLocation] = useState(pickupLocation);
    const [filter_poNumber, setPONumber] = useState(poNumber);
    const [filter_fromDate, setFromDate] = useState(fromDate);
    const [filter_toDate, setToDate] = useState(toDate);
    const [filter_product, setProduct] = useState(product);
    const [isApplyFilterClicked, setIsApplyFilterClicked] = useState(false);

    useEffect(() => {
        console.log('useEffect - customer', customer);
        setSearchPhrase('');
        setPONumber('');
        // setFromDate(null);
        // setToDate(null);
        setProduct('');
        setPickupLocation('');
    }, [customer]);

    useEffect(() => {
        setPONumber(poNumber);
    }, [poNumber]);

    useEffect(() => {
        setFromDate(fromDate);
    }, [fromDate]);

    useEffect(() => {
        setToDate(toDate);
    }, [toDate]);

    useEffect(() => {
        setProduct(product);
    }, [product]);

    useEffect(() => {
        setPickupLocation(pickupLocation);
    }, [pickupLocation]);

    const updateSearchPhrase = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const searchPhrase = search && search.current ? search.current.value : '';
            // setSearchPhrase(searchPhrase);
            // dispatch(PurchaseOrderActions.setSearchPhrase(searchPhrase));
        }
    };

    const onSearchChange = (e): void => {
        console.log('onChange', e.target.value);
        setSearchPhrase(e.target.value);
    };

    const onFromDateChange = (e): void => {
        console.log('onFromDateChange', e.target.value);
        // setIsApplyFilterClicked(false);
        // if (e.target.value !== '') {
        setFromDate(e.target.value);
        // dispatch(PurchaseOrderActions.setFromDate(e.target.value));
        // }
    };

    const onToDateChange = (e): void => {
        console.log('onToDateChange', e.target.value);
        // setIsApplyFilterClicked(false);
        // if (e.target.value !== '') {
        setToDate(e.target.value);
        // dispatch(PurchaseOrderActions.setToDate(e.target.value));
        // }
    };

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        document.body.classList.toggle('hide-sidebar', isOpen);
    }, [isOpen]);

    return (
        <div id="jobsFilterContainer" className="JobManagement-Filter col-md-3 pr-0">
            <div className="Filter-Container">
                <div>
                    <div className="accordion">
                        <div className="FilterItem-Card card">
                            <div className="CustomToggle-Container">
                                <div className="CustomToggle-Header">
                                    <p className="CustomToggle-Title">Orders Inquiry</p>
                                    <Button
                                        className="Filter-SelectAll"
                                        variant="link"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <i className="material-icons">swap_horiz</i>
                                    </Button>
                                </div>
                            </div>
                            <div className="CustomToggle-Divider"></div>
                            <div className="collapse show">
                                <div className="FilterItem-Body FilterItem-BodyPadding card-body">
                                    <div className="SearchBar-Container">
                                        <i className="material-icons SearchBar-Icon">search</i>
                                        <input
                                            id="textSearch"
                                            type="text"
                                            placeholder="Search"
                                            onChange={e => onSearchChange && onSearchChange(e)}
                                            className="SearchBar-Input"
                                            ref={search}
                                            value={filter_searchPhrase}
                                            // value={state.messageList.searchPhrase}
                                            onKeyDown={(e): void => updateSearchPhrase(e)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Date From <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${
                                                        isApplyFilterClicked && !filter_fromDate
                                                            ? 'Filter-Error-Required'
                                                            : ''
                                                    }`}
                                                    required
                                                    value={filter_fromDate ? filter_fromDate : ''}
                                                    onChange={e => onFromDateChange && onFromDateChange(e)}
                                                />
                                                {isApplyFilterClicked && !filter_fromDate && (
                                                    <label style={{ color: 'red' }}>Date from is required</label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Date To <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${
                                                        isApplyFilterClicked && !filter_toDate
                                                            ? 'Filter-Error-Required'
                                                            : ''
                                                    }`}
                                                    required
                                                    value={filter_toDate ? filter_toDate : ''}
                                                    onChange={e => onToDateChange && onToDateChange(e)}
                                                />
                                                {isApplyFilterClicked && !filter_toDate && (
                                                    <label style={{ color: 'red' }}>Date to is required</label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
