import gql from 'graphql-tag';

export default gql`
    query GetBolList($input: GetAllBOLInput) {
        getBolList(input: $input) {
            bolNo
            alternateBOLNumber
            pickUpLocation
            parentName
            quantityShipped
            uom
            product
            poNo
            shipDate
            invoiceNo
            invoiceType
            vehicleNo
            wellName
            scaleTicket
            coa
            totalRecordsCount
        }
    }
`;
