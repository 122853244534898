import gql from 'graphql-tag';

export default gql`
    query GetReportProductList($input: GetReportInput) {
        getReportProductList(input: $input) {
            productNumber
            productName
        }
    }
`;
