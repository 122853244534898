import gql from 'graphql-tag';

export default gql`
    query GetAllOpenInvoiceList($input: GetAllOpenInvoiceInput) {
        getAllOpenInvoiceList(input: $input) {
            invoiceNo
            parentCustomer
            documentCompany
            shipName
            shipCity
            invoiceDate
            netDueDate
            closedDate
            pastDueDays
            grossAmount
            openAmount
            invoiceType
            poNumber
            invoiceStatus
            taxAmount
            remarks
            salesDocumentNumber
            salesDocumentType
            totalRecordsCount
        }
    }
`;
