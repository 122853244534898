import React from 'react';
import './DashboardMyInvoices.scss';
import { Query, QueryResult } from 'react-apollo';
import { GET_ALL_INVOICE_LIST, GET_ALL_PURCHASEORDER_LIST } from '../../graphql/client/queries';
import { useStoreContext } from '../App/StoreProvider';
import { Error, Loading } from '../_common';
import { GetInvoiceList, GetInvoiceList_getInvoiceList as Invoice } from '../../types/schemaTypes';
import fountainPen from '../../assets/fountainPen.png';
import Table from 'react-bootstrap/Table';
import { Routes } from '../../utils/Routes';
import moment from 'moment';

const DashboardMyInvoices = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        customerList: { customer },
    } = state;

    const openInvoices = (): void => {
        const URL = `${window.location.origin}${Routes.INVOICES.route}`;
        window.location.replace(URL);
    };

    const variables = {
        input: {
            searchString: '',
            customer,
            fromDate: null,
            toDate: null,
            addressNumber: 0,
            invoiceStatus: 1,
            pageSize: 10,
            offSet: 0,
            sortParam: 'invoiceDate',
            sortDirection: 'desc',
            invoiceNumber: '',
            poNumber: '',
        },
    };
    return (
        <Query query={GET_ALL_INVOICE_LIST} variables={variables} skip={customer === 0} fetchPolicy="network-only">
            {({
                error,
                data,
                loading,
                refetch,
                fetchMore,
            }: QueryResult<GetInvoiceList | undefined>): JSX.Element | null => {
                const invoiceList: any[] = [];
                if (data && data.getInvoiceList && data.getInvoiceList.length !== 0) {
                    data.getInvoiceList.forEach((element: Invoice | null): void => {
                        if (element !== null) {
                            invoiceList.push(element);
                        }
                    });
                }
                return (
                    <div className="MyInvoices-Container">
                        {customer != 0 && error && <Error error={error} />}
                        <div>
                            <label className="MyInvoices-Headings">My Invoices</label>
                            &nbsp;&nbsp;
                            <img alt="" src={fountainPen} className="Logo-Pen" />
                        </div>
                        <label className="Label-Class">Find the most recent Invoices below:</label>
                        <div className="MyInvoices-Table-Container">
                            {customer != 0 && <div className="Loading-Class">{loading && <Loading />}</div>}
                            <table className="MyInvoices-Table">
                                <thead className="MyInvoices-Table-Head">
                                    <tr className="MyInvoices-Table-Rows">
                                        <th className="MyInvoices-Table-Heading">Invoice Type</th>
                                        <th className="MyInvoices-Table-Heading">Invoice No.</th>
                                        <th className="MyInvoices-Table-Heading">Invoice Date</th>
                                        <th className="MyInvoices-Table-Heading">Due Date</th>
                                        <th className="MyInvoices-Table-Heading">Open Amount</th>
                                    </tr>
                                </thead>
                                <tbody className="MyInvoices-Table-Body">
                                    {invoiceList.map((info, index) => (
                                        <tr className="MyInvoices-Table-Rows" key={index}>
                                            <td className="MyInvoices-Table-Values">{info.invoiceType}</td>
                                            <td className="MyInvoices-Table-Values">{info.invoiceNo}</td>
                                            <td className="MyInvoices-Table-Values">{info.invoiceDate}</td>
                                            <td className="MyInvoices-Table-Values">{info.netDueDate}</td>
                                            <td className="MyInvoices-Table-Values">{`$${info.openAmount}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="MyInvoices-Button-class">
                            <button className="MyInvoices-Dashboard-button" onClick={openInvoices}>
                                See All Invoices
                            </button>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default DashboardMyInvoices;
