import gql from 'graphql-tag';

export default gql`
    query GetReportPickupLoction($input: GetReportInput) {
        getReportPickupLoction(input: $input) {
            pickUpLocationId
            pickUpLocation
        }
    }
`;
