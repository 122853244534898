import { combineReducers } from './combineReducers';
import { authReducer, initialAuthState } from '../../store/reducers/auth';
import { useReducer } from 'react';
import { State } from '../../store/types';
import { userListReducer, initialUserListState } from '../../store/reducers/userList/userListReducer';
import { customerListReducer, initialCustomerListState } from '../../store/reducers/customerList/customerListReducer';
import {
    customerParentListReducer,
    initialCustomerParentListState,
} from '../../store/reducers/customerParentList/customerParentListReducer';
import {
    purchaseOrderReducer,
    initialPurchaseOrderState,
} from '../../store/reducers/purchaseOrder/purchaseOrderReducer';
import { bolReducer, initialBolState } from '../../store/reducers/bol/bolReducer';
import { invoiceReducer, initialInvoiceState } from '../../store/reducers/invoice/invoiceReducer';
import { reportReducer, initialReportState } from '../../store/reducers/report/reportReducer';

const initialState: State = {
    auth: initialAuthState,
    customerList: initialCustomerListState,
    customerParentList: initialCustomerParentListState,
    userList: initialUserListState,
    purchaseOrder: initialPurchaseOrderState,
    bol: initialBolState,
    invoice: initialInvoiceState,
    report: initialReportState,
};

const rootReducer = combineReducers({
    auth: authReducer,
    customerList: customerListReducer,
    customerParentList: customerParentListReducer,
    userList: userListReducer,
    purchaseOrder: purchaseOrderReducer,
    bol: bolReducer,
    invoice: invoiceReducer,
    report: reportReducer,
});

// Custom hook to apply Store to top-level component.
// Used by StoreContext to inject context to all components (8/4/19) - BS
const useStore = (): [State, React.Dispatch<unknown>] => {
    const store = useReducer(rootReducer, initialState) as [State, React.Dispatch<unknown>];

    return store;
};

export { useStore, initialState };
