import { Reducer } from 'react';
import { Actions } from '../../actions/bol';
import { Action } from '../../types';
import { BolState } from '../../types/bolState';
import { Actions as CustomerAction } from '../../actions/customerList';
import moment from 'moment';

const currentDate = new Date();
const toDate = new Date(new Date().setDate(currentDate.getDate()));
const fromDate = new Date(new Date().setDate(currentDate.getDate() - 90));
const fromDateStr = moment(fromDate).format('YYYY-MM-DD');
const toDateStr = moment(toDate).format('YYYY-MM-DD');

console.log('fromDateStr', fromDateStr, 'toDateStr', toDateStr);

const initialBolState: BolState = {
    searchPhrase: '',
    customer: 0,
    fromDate: fromDateStr,
    toDate: toDateStr,
    bolNumber: '',
    invoiceNo: '',
    region: '0',
    pickupLocation: 0,
    wellName: '',
    scaleTicket: '',
    poNo: '',
    relatedBol: 0,
};

const bolReducer: Reducer<BolState, Action> = (state, action): BolState => {
    console.log('bolReducer - purchaseOrderReducer', action);
    let newState = { ...state };

    switch (action.type) {
        case Actions.FILTER_SEARCHSTRING:
            newState = { ...state, searchPhrase: action.payload };
            break;
        case Actions.FILTER_CUSTOMER_SELECT:
            newState = { ...state, customer: Number(action.payload) };
            break;
        case Actions.FILTER_FROMDATE_SELECT:
            newState = { ...state, fromDate: action.payload };
            break;
        case Actions.FILTER_TODATE_SELECT:
            newState = { ...state, toDate: action.payload };
            break;
        case Actions.FILTER_BOLNUMBER:
            newState = { ...state, bolNumber: action.payload };
            break;
        case Actions.FILTER_REGION:
            newState = { ...state, region: action.payload };
            break;
        case Actions.FILTER_PICKUPLOCATION:
            newState = { ...state, pickupLocation: Number(action.payload) };
            break;
        case Actions.FILTER_WELLNAME:
            newState = { ...state, wellName: action.payload };
            break;
        case Actions.FILTER_ALTBOL:
            newState = { ...state, scaleTicket: action.payload };
            break;
        case Actions.FILTER_PONO:
            newState = { ...state, poNo: action.payload };
            break;
        case Actions.FILTER_INVOICE:
            newState = { ...state, invoiceNo: action.payload };
            break;
        case Actions.BOL_ONCLICK_RELATEDBOL:
            newState = { ...state, relatedBol: action.payload };
            break;
        case Actions.BOL_FILTER_ACTION_APPLYFILTER:
            newState = {
                ...state,
                searchPhrase: action.payload.searchPhrase,
                fromDate: action.payload.fromDate,
                toDate: action.payload.toDate,
                bolNumber: action.payload.bolNumber,
                invoiceNo: action.payload.invoiceNo,
                region: action.payload.region,
                pickupLocation: action.payload.pickupLocation,
                wellName: action.payload.wellName,
                scaleTicket: action.payload.scaleTicket,
                poNo: action.payload.poNo,
            };
            break;
        case Actions.BOL_FILTER_ACTION_RESETFILTER:
            newState = {
                ...state,
                searchPhrase: '',
                fromDate: fromDateStr,
                toDate: toDateStr,
                bolNumber: '',
                invoiceNo: '',
                region: '0',
                pickupLocation: 0,
                wellName: '',
                scaleTicket: '',
                poNo: '',
            };
            break;
        case CustomerAction.UPDATE_CUSTOMER_ID:
            newState = {
                ...state,
                searchPhrase: '',
                fromDate: fromDateStr,
                toDate: toDateStr,
                bolNumber: '',
                invoiceNo: '',
                region: '0',
                pickupLocation: 0,
                wellName: '',
                scaleTicket: '',
                poNo: '',
            };
            break;
    }

    return newState;
};

export { initialBolState, bolReducer };
